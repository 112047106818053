import { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

import PublicPage from '~/components/Page/PublicPage'
import TermsView from '~/views/Public/Terms/TermsView'

import { ROUTE_FAQS, ROUTE_TERMS } from './Routes'

const LazyFaqsView = lazy(async () => await import('~/views/Public/FAQ/FAQView'))

const PublicRoutes = (
  <Route element={<PublicPage />}>
    <Route path={ROUTE_FAQS} element={<LazyFaqsView />} />
    <Route path={ROUTE_TERMS} element={<TermsView />} />
  </Route>
)

export default PublicRoutes
