import { combineReducers } from 'redux'

import auth from '../features/auth/authSlice'
import notification from '../features/notifications/notificationSlice'

const rootReducer = combineReducers({
  auth,
  notification,
})

export default rootReducer
