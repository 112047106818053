import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'

import JoinTheRefillution from '~/assets/images/join-the-refillution.svg'

interface ConfirmSignUpProps {
  email: string
  isLoading: boolean
  onResendCode: () => Promise<void>
  onConfirm: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  code: yup.string().min(6, 'Confirmation Code must be at least 6 characters').required('Confirmation Code is required'),
})

const ConfirmSignUp: React.FunctionComponent<ConfirmSignUpProps> = ({ email, isLoading, onResendCode, onConfirm }) => {
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: onConfirm,
  })

  const handleResendCode = (): void => {
    void onResendCode()
  }

  return (
    <Box sx={{ mt: 6, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>Verify Your Email</Typography>
      <Typography variant="body1" gutterBottom>We sent you a six-digit code to {email || 'your email'}. Please enter the code to verify your email address.</Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="code"
          name="code"
          label="Confirmation Code"
          margin="normal"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code} />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Verify</LoadingButton>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
          <Typography>
            Didn't receive code?
          </Typography>
          <Link onClick={handleResendCode} sx={{ cursor: 'pointer' }}>
            Send code again
          </Link>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Box component="img" src={JoinTheRefillution} alt="Join the Refillution" sx={{ position: 'relative', right: '-25px', mt: 4, width: '300px' }} />
      </Box>
    </Box>
  )
}

export default ConfirmSignUp
