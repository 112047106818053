import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '~/redux/store'
import { login, resendSignUpCode } from '~/redux/features/auth/actions'
import { FormikValues } from 'formik'
import { Helmet } from 'react-helmet-async'

import SignIn from './SignIn'

import { ROUTE_CONFIRM_SIGN_UP } from '~/routes/Routes'
import { storeSignUp } from '~/redux/features/auth/authSlice'

import { AWSException } from '~/types/errors/AWSException'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

const SignInView: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const signIn = async (values: FormikValues): Promise<void> => {
    const { email, password } = values

    setLoading(true)

    try {
      await dispatch(login({
        email,
        password,
      })).unwrap()
    } catch (error) {
      switch ((error as AWSException).name) {
        case 'UserNotConfirmedException':
          dispatch(storeSignUp({ email, password }))
          await dispatch(resendSignUpCode(email)).unwrap()
          navigate(ROUTE_CONFIRM_SIGN_UP)
          break
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          dispatch(addNotification({
            type: 'error',
            message: 'Incorrect email or password',
          }))
          break
        default:
          dispatch(addNotification({
            type: 'error',
            message: 'Sorry, there was a problem signing in',
          }))
      }

      setLoading(false)
    }
  }

  return <>
    <Helmet>
      <title>Sign In | Ecover Loyalty Rewards</title>
    </Helmet>
    <SignIn isLoading={loading} onSignIn={signIn} />
  </>
}

export default SignInView
