
import { Box } from '@mui/material'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import FlowerLoader from '~/components/Loader/FlowerLoader'
import { LocationState } from '~/types/location/Location'

import { ROUTE_HOME, ROUTE_LANDING, ROUTE_LOGIN } from './Routes'

interface AuthRouteGuardProps {
  isAuthenticating: boolean
  isAuthenticated: boolean
}

const AuthRouteGuard: React.FC<AuthRouteGuardProps> = ({ isAuthenticating, isAuthenticated }) => {
  const location = useLocation()
  const state = location.state as LocationState

  if (isAuthenticating) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <FlowerLoader sx={{ width: '100px', height: '100px' }} />
    </Box>
  }

  if (isAuthenticated) {
    if ([ROUTE_LOGIN, ROUTE_LANDING].includes(location.pathname)) {
      if (state?.from) {
        return <Navigate to={state.from} replace />
      }

      return <Navigate to={ROUTE_HOME} replace />
    }
  }

  return <Outlet />
}

export default AuthRouteGuard
