import { styled } from '@mui/material'

import BottleBeingRefilledImage from '~/assets/images/bottle-being-refilled@2x.png'

const Bottle = styled('img')`
  position: relative;
  top: -20px;
  left: -800px;
  width: auto;
  min-height: 600px;
  max-height: 1000px;
  height: 100%;
  transition: all 0.5s ease-in-out;

  @media (max-height: 600px) {
    left: -480px;
  }

  @media (min-height: 600px) and (max-height: 650px) {
    left: -500px;
  }

  @media (min-height: 650px) and (max-height: 700px) {
    left: -550px;
  }

  @media (min-height: 700px) and (max-height: 800px) {
    left: -600px;
  }

  @media (min-height: 800px) and (max-height: 900px) {
    left: -650px;
  }

  @media (min-height: 900px) and (max-height: 950px) {
    left: -725px;
  }

  @media (min-height: 950px) and (max-height: 1000px) {
    left: -775px;
  }
`

const BottleBeingRefilled: React.FC = () => {
  return <Bottle src={BottleBeingRefilledImage} />
}

export default BottleBeingRefilled
