import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { FormikValues, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import { PasswordField } from '~/components/Form/PasswordField/PasswordField'
import { login } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch, RootState } from '~/redux/store'
import { AWSException } from '~/types/errors/AWSException'
import { useSelector } from 'react-redux'

interface VerifyAccessProps {
  children: React.ReactNode
}

const validationSchema = yup.object({
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup.string().required('Password is required'),
})

const VerifyAccess: React.FC<VerifyAccessProps> = ({ children }) => {
  const [initialCheckComplete, setInitialCheckComplete] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [verified, setVerified] = useState(false)
  const dispatch = useAppDispatch()
  const lastLoginAt = useSelector((state: RootState) => state.auth.lastLoginAt)
  const user = useSelector((state: RootState) => state.auth.user)

  const verifyUser = async (values: FormikValues): Promise<void> => {
    const { email, password } = values

    if (user?.email.toLowerCase() !== email.toLowerCase()) {
      dispatch(addNotification({
        type: 'error',
        message: 'Provided email does not match the email used on initial sign-in',
      }))

      return
    }

    setLoading(true)

    try {
      await dispatch(login({
        email,
        password,
      })).unwrap()

      setLoading(false)
      setVerified(true)
    } catch (error) {
      switch ((error as AWSException).name) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          dispatch(addNotification({
            type: 'error',
            message: 'Incorrect email or password',
          }))
          break
        default:
          dispatch(addNotification({
            type: 'error',
            message: 'Sorry, there was a problem verifying your user',
          }))
      }

      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: verifyUser,
  })

  useEffect(() => {
    void checkIfUserRequiresVerification()
  }, [])

  const checkIfUserRequiresVerification = async (): Promise<void> => {
    const now = (new Date()).getSeconds()
    const hourInSeconds = 3600

    if (lastLoginAt && ((now - lastLoginAt) < hourInSeconds)) {
      setVerified(true)
    }

    setInitialCheckComplete(true)
  }

  if (!initialCheckComplete) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <CircularProgress />
    </Box>
  }

  if (!verified) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <Typography variant="h4" align="center" gutterBottom>Verify it's you</Typography>
        <Typography align="center" gutterBottom>It has been a while since you logged in, please login to access your account.</Typography>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          margin="normal"
          value={formik.values.email}
          autoComplete="username"
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          required />
        <PasswordField
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          autoComplete="current-password"
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          required
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Submit</LoadingButton>
      </Box>
    </Box>
  }

  return <>{children}</>
}

export default VerifyAccess
