import Bugsnag from '@bugsnag/js'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import VerifyAccess from '~/components/Auth/VerifyAccess'
import { promotion } from '~/config/promotion'
import { signOut } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { RootState, useAppDispatch } from '~/redux/store'
import PromotionalUserService from '~/services/PromotionalUserService'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'
import { PromotionalUserUpdatePayload } from '~/types/promotionalUser/request/PromotionalUserUpdatePayload'

import PersonalDetails from './PersonalDetails'

const PersonalDetailsView: React.FC = (): JSX.Element => {
  const [user, setUser] = useState<PromotionalUser | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const authUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useAppDispatch()

  const fetchUser = async (): Promise<void> => {
    if (!isFetching) setIsFetching(true)

    if (!authUser) return await dispatch(signOut()).unwrap()

    try {
      const user = await PromotionalUserService.getPromotionalUser(
        promotion.id,
        authUser.userId,
      )
      setUser(user)
    } catch (error) {
      if (!isAbortError(error)) dispatch(addNotification({ message: 'Unable to fetch user', type: 'error' }))

      if (isNotifiable(error)) {
        Bugsnag.notify(error)
      }
    } finally {
      setIsFetching(false)
    }
  }

  const save = async (values: PromotionalUserUpdatePayload): Promise<void> => {
    if (authUser) {
      try {
        setIsLoading(true)
        await PromotionalUserService.updatePromotionalUser(promotion.id, authUser.userId, values)
        dispatch(addNotification({ type: 'success', message: 'Saved' }))
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
        dispatch(addNotification({ type: 'error', message: 'Failed to update personal details' }))
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleFetchUser = (): void => {
    void fetchUser()
  }

  return <>
    <Helmet>
      <title>Account - Personal Details | Ecover Loyalty Rewards</title>
    </Helmet>
    <VerifyAccess>
      <PersonalDetails user={user} isFetching={isFetching} isSaving={isLoading} onFetchUser={handleFetchUser} onSave={save} />
    </VerifyAccess>
  </>
}

export default PersonalDetailsView
