import { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, Dialog, styled, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import CloseIcon from '@mui/icons-material/Close'
import WavingHandIcon from '@mui/icons-material/WavingHand'

import BottleBeingRefilled from '~/components/Graphics/BottleBeingRefilled'
import { BrandColor } from '~/config/theme'
import { getUserReduction } from '~/helpers/promotionalUser/getUserReduction'
import { formatLoyaltyPoints } from '~/helpers/promotionalUser/loyaltyPoints'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { ROUTE_REFILL, ROUTE_REWARDS } from '~/routes/Routes'
import { EnvironmentalImpactMaterial } from '~/types/common/EnvironmentalImpact'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'

import MustardBadge from '~/assets/images/mustard-badge.svg'

interface HomeProps {
  user: PromotionalUser | null
  fetchPromotionalUser: () => Promise<void>
}

const HiddenBox = styled(Box)`
  width: 100%;
  justify-content: center;
  display: flex;
  
  @media (max-height: 600px) {
    display: none;
  }
`

const Home: React.FC<HomeProps> = ({ user, fetchPromotionalUser }) => {
  const dispatch = useAppDispatch()
  const [showOffer, setShowOffer] = useState(false)

  useEffect(() => {
    void fetchUser()

    setShowOffer(Boolean(!localStorage.getItem('farewell')) ?? false)
  }, [])

  const fetchUser = async (): Promise<void> => {
    try {
      await fetchPromotionalUser()
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Failed to load updated user information' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const runAnimation = (): boolean => {
    return Boolean(user)
  }

  const onDoublePointsDismiss = (): void => {
    setShowOffer(false)
    localStorage.setItem('farewell', 'true')
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, py: 2, paddingLeft: { md: '400px' }, justifyContent: 'center', alignItems: 'center', maxHeight: '800px' }}>
    <Container sx={{
      display: { xs: 'none', md: 'flex' },
      position: 'absolute',
      top: 0,
      center: 0,
      height: '100%',
    }}>
      <BottleBeingRefilled />
    </Container>
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: { xs: 1, md: 0 }, justifyContent: 'center', alignItems: 'center', mb: { xs: 2, md: 4 } }}>
      <Box sx={{ position: 'relative', pt: 1 }}>
        <Box sx={{
          background: BrandColor.BLACK,
          color: 'white',
          borderColor: 'white',
          borderWidth: 10,
          borderRadius: 10,
          borderStyle: 'solid',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 4,
          textAlign: 'center',
          minHeight: { xs: '110px', md: '190px' },
          maxHeight: { xs: '175px', lg: '200px' },
          minWidth: 150,
          width: runAnimation() ? 500 : 0,
          maxWidth: { xs: runAnimation() ? 300 : 150, md: runAnimation() ? 500 : 150 },
          transform: 'rotate(-7.5deg)',
          transition: 'all 1.5s ease',
          overflow: 'hidden',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="title" fontSize="1.25rem" noWrap>You Have Earned</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
            {runAnimation()
              ? <Typography variant="title" sx={{ fontSize: { xs: 60, sm: 80, md: 100 } }} noWrap>{formatLoyaltyPoints(user?.loyaltyPoints)}</Typography>
              : <CircularProgress />}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="title" fontSize="1.25rem" noWrap>Rewards</Typography>
          </Box>
        </Box>
        <Box component="img" src={MustardBadge} sx={{ position: 'absolute', right: '-100px', bottom: '-50px', width: '150px' }} />
      </Box>
      <Box sx={{
        position: 'relative',
        transition: 'all 3s ease',
        left: -40,
        top: -10,
        background: BrandColor.BLUE,
        color: 'white',
        py: 2,
        px: { xs: 6, md: 4, mdl: 6 },
        borderRadius: 12,
        zIndex: 2,
        transform: 'rotate(3deg)',
        textAlign: 'center',
        width: { xs: runAnimation() ? 250 : 0, md: runAnimation() ? 350 : 0 },
        overflow: 'hidden',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {runAnimation()
            ? <Typography variant="title" sx={{ fontSize: { xs: 16, md: 24, mdl: 26, transition: 'all 5s ease' } }} noWrap>
                {getUserReduction(user, EnvironmentalImpactMaterial.Plastic)} Plastic Saved
              </Typography>
            : <CircularProgress style={{ display: 'inline-block', width: 20, height: 20 }} />}
        </Box>
        <Typography variant="caption" noWrap sx={{ transition: 'all 2s ease', opacity: runAnimation() ? 1 : 0 }}>vs new Ecover bottles</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'center', textAlign: 'center', width: '100%' }}>
      <Button component={RouterLink} to={ROUTE_REWARDS} variant="contained" fullWidth sx={{ display: 'block', mb: 1, mr: { lg: 1 }, maxWidth: '450px' }}>View Rewards</Button>
      <HiddenBox><Button component={RouterLink} to={ROUTE_REFILL} variant="outlined" fullWidth sx={{ display: 'block', mt: 1, mb: 2, ml: { lg: 1 }, maxWidth: '450px' }}>Add A Refill</Button></HiddenBox>
    </Box>
    <Dialog
      open={showOffer}
      onClose={onDoublePointsDismiss}
      onBackdropClick={onDoublePointsDismiss}
      sx={{ overflow: 'visible' }}
      PaperProps={{
        elevation: 0,
        sx: { overflowY: 'visible', backgroundColor: 'transparent' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: BrandColor.BLUE, p: 4, pt: 18, textAlign: 'center', borderRadius: 5 }}>
        <Button sx={{ position: 'absolute', top: 0, right: 0, textDecoration: 'none', color: 'white', fontSize: 80 }} onClick={onDoublePointsDismiss}>
          <CloseIcon sx={{ width: '2rem', height: '2rem' }} />
        </Button>
        <Box sx={{ position: 'absolute', top: -30, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, backgroundColor: BrandColor.GREEN, borderRadius: 100, width: 100, height: 100, borderWidth: 10, borderStyle: 'solid', borderColor: 'white' }}>
          <WavingHandIcon sx={{ color: 'white', width: '5rem', height: '5rem', ml: 1 }} />
        </Box>
        <Typography variant="h4" sx={{ color: 'white', fontWeight: 600 }} gutterBottom>Double Refill Rewards</Typography>
        <Typography variant="body1" sx={{ color: 'white' }}>Our Refill Rewards scheme is closing this month. We started it as a trial, so a big thank you to all of you who refilled your bottles and topped up on Rewards. High five.</Typography>
      </Box>
    </Dialog>
  </Box>
}

export default Home
