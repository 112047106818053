const AtHome: React.FC = () => {
  return <ol>
    <li>Order your refill online or fill up Instore</li>
    <li>Upload your receipt</li>
    <li>Confirm product details</li>
    <li>Earn money</li>
  </ol>
}

export default AtHome
