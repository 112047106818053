import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGN_UP } from '~/routes/Routes'

import RefillsRock from '~/assets/images/refills-rock.svg'
import { PasswordField } from '~/components/Form/PasswordField/PasswordField'

interface SignInProps {
  isLoading: boolean
  onSignIn: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup.string().required('Password is required'),
})

const SignIn: React.FunctionComponent<SignInProps> = ({ isLoading, onSignIn }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: onSignIn,
  })

  return <Box>
    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        margin="normal"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        required />
      <PasswordField
        fullWidth
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        required />
        <Box sx={{ textAlign: 'right' }}>
          <Link component={RouterLink} to={ROUTE_FORGOT_PASSWORD} variant="body2">
            Forgot password?
          </Link>
        </Box>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{ mt: 2 }}>Sign In</LoadingButton>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
      <Typography>
        Don't have an account?
      </Typography>
      <Link component={RouterLink} to={ROUTE_SIGN_UP} variant="button">
        Sign Up
      </Link>
    </Box>
    <Box sx={{ textAlign: 'right' }}>
      <Box component="img" src={RefillsRock} alt="Refills Rock" sx={{ position: 'relative', right: '-100px', width: '250px' }} />
    </Box>
  </Box>
}

export default SignIn
