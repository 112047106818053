import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { getPromotionalUser } from '~/redux/features/auth/actions'
import { RootState, useAppDispatch } from '~/redux/store'

import Home from './Home'

const HomeView: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.auth.promotionalUser)
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchPromotionalUser = async (): Promise<void> => {
    if (user?.userId) {
      await dispatch(getPromotionalUser({ userId: user?.userId, signal: abort.current.signal })).unwrap()
    }
  }

  return <>
    <Helmet>
      <title>Home | Ecover Loyalty Rewards</title>
    </Helmet>
    <Home user={user} fetchPromotionalUser={fetchPromotionalUser} />
  </>
}

export default HomeView
