import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, Container, IconButton, Link, styled, Toolbar, Tooltip, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PersonIcon from '@mui/icons-material/Person'
import { Home } from '@mui/icons-material'

import { ROUTE_ACCOUNT, ROUTE_HOME, ROUTE_MY_REFILLS, ROUTE_REFILL, ROUTE_REWARDS } from '~/routes/Routes'
import { RootState } from '~/redux/store'

import RefillLogo from '~/assets/images/refill-logo.svg'

interface PageProps {
  children: React.ReactNode
  route?: string
}

const PageBox = styled(Box)`
  min-height: calc(100vh - calc(100vh - 100%));
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  flex-grow: 1;
`

export const Page: React.FC<PageProps> = ({ children, route }) => {
  const user = useSelector((state: RootState) => state.auth.promotionalUser)

  const dashboardStyles = route === ROUTE_HOME
    ? {
        appBar: {
          height: { md: 150 },
        },
        logo: {
          width: { md: '400px' },
          top: { md: '20px' },
          height: { xs: 45, md: 'auto' },
          transform: 'rotate(-5deg)',
        },
      }
    : {}

  return <PageBox sx={{
    display: 'flex',
    flexDirection: 'column',
  }}>
    <AppBar elevation={0} position='static' sx={{
      height: 80,
      transition: 'all 0.2s ease-in-out',
      zIndex: 2,
      ...dashboardStyles.appBar,
    }}>
      <Container maxWidth='lg'>
        <Toolbar disableGutters sx={{ pt: 1 }}>
          <Link component={RouterLink} to={ROUTE_HOME}>
            <Box
              component="img"
              sx={{
                position: 'relative',
                height: { xs: 45 },
                mr: 2,
                transition: 'all 0.2s ease-in-out',
                ...dashboardStyles.logo,
              }}
              alt="Ecover Loyalty"
              src={RefillLogo}
            />
          </Link>
          <Box flexGrow={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button component={RouterLink} to={ROUTE_REFILL} sx={{ textDecoration: 'none' }}>Add Refill</Button>
            <Button component={RouterLink} to={ROUTE_REWARDS} sx={{ textDecoration: 'none' }}>Rewards</Button>
            <Button component={RouterLink} to={ROUTE_MY_REFILLS} sx={{ textDecoration: 'none' }}>My Refills</Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', color: 'black' }}>
            <Typography variant="h6" sx={{ display: { md: 'none', lg: 'inline-block' }, fontSize: 15, maxWidth: '175px' }} noWrap>{user?.firstName ? `Hi ${user?.firstName}` : ''}</Typography>
            <Tooltip title="View Account">
              <IconButton component={RouterLink} to={ROUTE_ACCOUNT}>
                <PersonIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} sx={{
      maxHeight: { xs: '100%', md: 'none' },
      overflowY: { xs: 'scroll', md: 'auto' },
      overflowX: 'hidden',
      mb: { xs: '58px' },
    }}>
      <Container maxWidth='lg' sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minWidth: '230px' }}>
        {children}
      </Container>
    </Box>
    <BottomNavigation showLabels >
      <BottomNavigationAction
        icon={<Home />}
        component={RouterLink}
        to={ROUTE_HOME}
        sx={{ flexGrow: { xs: 0, sm: 1 }, flexShrink: { xs: 2, sm: 1 } }}
        showLabel={false}
      />
      <BottomNavigationAction
        component={RouterLink}
        to={ROUTE_REFILL}
        label="Add Refill"
      />
      <BottomNavigationAction
        component={RouterLink}
        to={ROUTE_REWARDS}
        label="Rewards"
      />
      <BottomNavigationAction
        component={RouterLink}
        to={ROUTE_MY_REFILLS}
        label="My Refills"
      />
    </BottomNavigation>
  </PageBox>
}
