export const promotion = {
  id: import.meta.env.VITE_PROMOTION_ID as string ?? null,
  currency: 'GBP',
  country: 'GB',
  locale: 'en-GB',
  startDate: '2022-01-01',
}

export const rewardPromotion = {
  id: import.meta.env.VITE_REWARD_PROMOTION_ID as string ?? null,
  currency: 'GBP',
  country: 'GB',
}
