import { Fragment } from 'react'
import { Box, Chip, CircularProgress, Divider, List, ListItem, ListItemIcon, Typography } from '@mui/material'
import { format } from 'date-fns'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import RedeemIcon from '@mui/icons-material/Redeem'
import CancelIcon from '@mui/icons-material/Cancel'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Money } from '@metrisk/money'

import { promotion } from '~/config/promotion'
import { UserClaim } from '~/types/claim/UserClaim'

import { LoadingButton } from '@mui/lab'
import CashPayoutIcon from '~/components/Icon/CashPayoutIcon'
import { BrandColor } from '~/config/theme'

interface MyRefillsProps {
  claims: UserClaim[]
  end: boolean
  fetched: boolean
  loading: boolean
  onLoadMore: () => Promise<void>
}

const MyRewards: React.FunctionComponent<MyRefillsProps> = ({ claims, fetched, loading, end = true, onLoadMore }) => {
  const getClaimIcon = (status: string): JSX.Element => {
    switch (status) {
      case 'Rejected':
        return <CancelIcon />
      case 'Claimed':
      case 'Processing Payment':
      case ' Dispatching':
        return <CheckCircleIcon />
      default:
        return <PendingIcon />
    }
  }

  const getClaimChipStyle = (status: string): { backgroundColor: string, color: string } => {
    switch (status) {
      case 'Rejected':
        return { backgroundColor: BrandColor.BLACK, color: '#fff' }
      case 'Claimed':
      case 'Processing Payment':
      case ' Dispatching':
        return { backgroundColor: BrandColor.GREEN, color: '#fff' }
      default:
        return { backgroundColor: BrandColor.BLUE, color: '#fff' }
    }
  }

  const handleLoadMore = (): void => {
    void onLoadMore()
  }

  return (
    <Box>
      <Typography variant='h4' gutterBottom>My Rewards</Typography>
      <Typography>Here are all your Refill Rewards.</Typography>
      {!fetched && loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 4 }}><CircularProgress /></Box>}
      {fetched && !loading && claims.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', opacity: 0.5, py: 4 }}>
        <RedeemIcon sx={{ mr: 2, fontSize: 50 }} />
        <Typography variant='body1' gutterBottom>No reward history found</Typography>
      </Box>}
      <List>
        {claims.map((claim, index) => (
          <Fragment key={claim.shortId}>
            <ListItem alignItems="center" sx={{ py: 2 }}>
              <ListItemIcon sx={{ color: 'black', mt: 0, mr: { xs: 1, md: 2 } }}><CashPayoutIcon sx={{ width: { xs: 40, md: 60 }, fontSize: { xs: 40, md: 60 } }} /></ListItemIcon>

              <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-start', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RedeemIcon sx={{ mr: 1 }} fontSize="small" />
                    <Typography variant="body1" textOverflow={'ellipsis'}>{claim.purchaseMethod ?? claim.prize.name}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon sx={{ mr: 1 }} fontSize="small" />
                    <Typography variant="body2" textOverflow={'ellipsis'}>{format(new Date(claim.createdAt), 'MMMM d, yyyy')}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: { xs: 'flex', sm: 'block' }, marginLeft: { sm: 'auto' }, marginTop: { xs: 1, sm: 0 }, textAlign: 'right' }}>
                  <Box sx={{ mb: 1, mr: { xs: 1, sm: 0 } }}><Chip icon={getClaimIcon(claim.status)} label={claim.status} size="small" sx={{ ...getClaimChipStyle(claim.status) }} /></Box>
                  <Chip icon={<CurrencyPoundIcon />} label={`${claim?.prize?.amount ? (new Money(claim.prize.amount, promotion.currency)).toDecimal().toFixed(2) : ''}`} size="small" />
                </Box>
              </Box>
            </ListItem>
            {index !== claims.length - 1 && <Divider variant="inset" component="li" />}
          </Fragment>
        ))}
      </List>
      <Box sx={{ textAlign: 'center', mt: end ? 4 : 0 }}>
        {!end && <LoadingButton loading={loading} onClick={handleLoadMore}>Load More</LoadingButton>}
      </Box>
    </Box>
  )
}

export default MyRewards
