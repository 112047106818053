export const ROUTE_LANDING = '/'

// Auth
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGN_UP = '/sign-up'
export const ROUTE_CONFIRM_SIGN_UP = '/confirm-sign-up'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'

// Pages
export const ROUTE_HOME = '/dashboard'
export const ROUTE_REFILL = '/refill'
export const ROUTE_REWARDS = '/rewards'
export const ROUTE_MY_REFILLS = '/my-refills'
export const ROUTE_MY_REWARDS = '/my-rewards'
export const ROUTE_ACCOUNT = '/account'
export const ROUTE_ACCOUNT_DETAILS = '/account/details'
export const ROUTE_ACCOUNT_PASSWORD = '/account/password'
export const ROUTE_ACCOUNT_LOCATION_SERVICES = '/account/location-services'
export const ROUTE_CLAIM_INFORMATION_REQUEST = '/claim/information-request'

// Public Pages
export const ROUTE_FAQS = '/faqs'
export const ROUTE_TERMS = '/terms'
