import * as yup from 'yup'
import { useState } from 'react'
import { FormikValues, useFormik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { ROUTE_LOGIN } from '~/routes/Routes'

import { useAppDispatch } from '~/redux/store'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { isAwsException, isNotifiable } from '~/types/guards/errors'
import Bugsnag from '@bugsnag/js'

interface RequestPasswordResetProps {
  onRequestReset: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  email: yup.string().email('Email is invalid').required('Email is required'),
})

const RequestPasswordReset: React.FunctionComponent<RequestPasswordResetProps> = ({ onRequestReset }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    setIsLoading(true)

    try {
      await onRequestReset(values)
    } catch (error) {
      if (isAwsException(error)) {
        switch (error.code) {
          case 'UserNotFoundException':
          case 'ResourceNotFoundException':
            dispatch(addNotification({ type: 'error', message: 'Did you mean to sign up?' }))
            break
          case 'LimitExceededException':
            dispatch(addNotification({ type: 'error', message: 'Too many attempts, try again later' }))
            break
          default:
            dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
            if (isNotifiable(error)) Bugsnag.notify(error)
        }
      } else {
        dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
      <Box>
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={formik.handleSubmit}>
          <Typography variant="h4" gutterBottom>Forgot your password?</Typography>
          <Typography variant="body2" gutterBottom>Please enter your email below and you will receive a code to your inbox to reset your password.</Typography>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            margin="normal"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            required />
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={isLoading}
            sx={{ mt: 2 }}>Send</LoadingButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
            <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
              Back to Sign In
            </Link>
          </Box>
    </Box>
  )
}

export default RequestPasswordReset
