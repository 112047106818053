import Bugsnag from '@bugsnag/js'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import { promotion } from '~/config/promotion'
import { signOut } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { RootState, useAppDispatch } from '~/redux/store'
import PromotionalUserService from '~/services/PromotionalUserService'
import { UserClaim } from '~/types/claim/UserClaim'
import { isAbortError, isNotifiable } from '~/types/guards/errors'

import MyRefills from './MyRefills'

const MyRefillsView: React.FunctionComponent = () => {
  const [claims, setClaims] = useState<UserClaim[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [fetched, setFetched] = useState<boolean>(false)
  const nextToken = useRef<string | null>(null)
  const abort = useRef(new AbortController())
  const authUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchRefills()
    return () => {
      abort.current.abort()
    }
  }, [])

  const fetchRefills = async (): Promise<void> => {
    if (!loading) setLoading(true)
    if (!authUser) return await dispatch(signOut()).unwrap()

    try {
      const response = await PromotionalUserService.getClaimsForPromotionalUser(
        promotion.id,
        authUser.userId,
        nextToken.current,
        { query: { searchPromotionId: promotion.id }, signal: abort.current.signal },
      )
      nextToken.current = response.nextToken ?? null
      setClaims([...claims, ...response.items])
    } catch (error) {
      if (!isAbortError(error)) dispatch(addNotification({ type: 'error', message: 'Unable to fetch refills' }))
      if (isNotifiable(error)) Bugsnag.notify(error)
    } finally {
      if (!abort.current.signal.aborted) {
        setLoading(false)
        if (!fetched) setFetched(true)
      }
    }
  }

  return <>
    <Helmet>
      <title>My Refills | Ecover Loyalty Rewards</title>
    </Helmet>
    <MyRefills claims={claims} fetched={fetched} loading={loading} end={nextToken?.current === null} onLoadMore={fetchRefills} />
  </>
}

export default MyRefillsView
