import { Box, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Link, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'
import { ROUTE_TERMS } from '~/routes/Routes'
import { LINK_PRIVACY_POLICY } from '~/constants/externalLinks'

interface AgreementsProps {
  isLoading: boolean
  onNextStep: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  marketing: yup.boolean(),
  agreeTerms: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  agreeLocationServices: yup.boolean().oneOf([true], 'You must agree to the location services'),
})

const Agreements: React.FC<AgreementsProps> = ({ isLoading, onNextStep }) => {
  const formik = useFormik({
    initialValues: {
      marketing: false,
      agreeTerms: false,
      agreeLocationServices: false,
    },
    validationSchema,
    onSubmit: onNextStep,
  })

  return (
    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4" gutterBottom>Terms & Privacy</Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>Location Services</Typography>

        <Typography variant="body1" gutterBottom>
          It is a condition of participation that location services are enabled on the device(s) used for submitting In-Store Refill claims.
        </Typography>

        <Typography variant="body1" gutterBottom>Non-continuous geolocation (this Application):</Typography>

        <Typography variant="body1" gutterBottom>
          This Application may collect, use, and share user location data in order to provide location-based services.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The geographic location is determined in a manner that isn't continuous, and allows the application to
          detect the position automatically.
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <FormGroup sx={{ my: 2 }}>
        <FormControlLabel
          control={
            <Checkbox name="marketing"
              value={formik.values.marketing}
              onChange={formik.handleChange}
              required
            />}
          label="I confirm that I want Ecover to send me interesting emails with news, innovations, collaborations, products and the occasional offer"
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox name="agreeTerms"
              value={formik.values.agreeTerms}
              onChange={formik.handleChange}
              required
            />}
          label={<>I have read and agree to the <Link href={ROUTE_TERMS} target="_blank">terms & conditions</Link> and <Link href={LINK_PRIVACY_POLICY} target="_blank">privacy policy</Link></>}
        />
        <FormHelperText error sx={{ mb: 2 }}>{formik.touched.agreeTerms && formik.errors.agreeTerms}</FormHelperText>
        <FormControlLabel
          control={
            <Checkbox name="agreeLocationServices"
              value={formik.values.agreeLocationServices}
              onChange={formik.handleChange}
              required
            />}
          label="I agree with Location Services being enabled when adding refills in store"
        />
        <FormHelperText error>{formik.touched.agreeLocationServices && formik.errors.agreeLocationServices}</FormHelperText>
      </FormGroup>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{ mt: 2 }}>SIGN UP</LoadingButton>
    </Box>
  )
}

export default Agreements
