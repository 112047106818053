import Bugsnag from '@bugsnag/js'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import { isNotifiable } from '~/types/guards/errors'

import Password from './Password'

const PasswordView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const save = async (values: { oldPassword: string, newPassword: string, confirmPassword: string }): Promise<void> => {
    setIsLoading(true)

    try {
      const authUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(authUser, values.oldPassword, values.newPassword)

      dispatch(addNotification({
        type: 'success',
        message: 'Password updated',
      }))

      navigate(ROUTE_ACCOUNT)
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      dispatch(addNotification({
        type: 'error',
        message: 'Failed to update password',
      }))
      setIsLoading(false)
    }
  }

  return <>
    <Helmet>
      <title>Account - Password | Ecover Loyalty Rewards</title>
    </Helmet>
    <Password isLoading={isLoading} onSave={save} />
  </>
}

export default PasswordView
