import { useRef, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { PurchaseMethod } from '~/constants/refill'
import AtHome from './AtHome'
import InStore from './InStore'

interface HowItWorksProps {
  closeButtonLabel: string
}

const HowItWorks: React.FC<HowItWorksProps> = ({ children, closeButtonLabel }) => {
  const [open, setOpen] = useState(false)
  const [purchaseMethod, setPurchaseMethod] = useState<PurchaseMethod>(PurchaseMethod.AT_HOME)
  const descriptionElementRef = useRef<HTMLElement>(null)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handlePurchaseMethod = (method: PurchaseMethod): void => {
    setPurchaseMethod(method)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            How It Works
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Button variant={purchaseMethod === PurchaseMethod.AT_HOME ? 'selected' : 'outlined'} sx={{ flexGrow: 1, mr: 2 }} onClick={() => handlePurchaseMethod(PurchaseMethod.AT_HOME)}>At Home</Button>
            <Button variant={purchaseMethod === PurchaseMethod.IN_STORE ? 'selected' : 'outlined'} sx={{ flexGrow: 1 }} onClick={() => handlePurchaseMethod(PurchaseMethod.IN_STORE)}>In-Store</Button>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {purchaseMethod === PurchaseMethod.AT_HOME ? <AtHome /> : <InStore />}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button variant="contained" onClick={handleClose} fullWidth>{closeButtonLabel}</Button>
        </DialogActions>
      </Dialog>
      <Box onClick={handleOpen}>{children}</Box>
    </>
  )
}

export default HowItWorks
