import { useState } from 'react'
import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Divider, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Bugsnag from '@bugsnag/js'

import { ROUTE_LOGIN } from '~/routes/Routes'
import { useAppDispatch } from '~/redux/store'
import { isAwsException, isNotifiable } from '~/types/guards/errors'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

interface ResetPasswordProps {
  onResetPassword: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  code: yup.string().required('Reset Code is required'),
  password: yup.string().required('Password is required'),
})

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({ onResetPassword }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    setIsLoading(true)

    try {
      await onResetPassword(values)
      dispatch(addNotification({ message: 'Password updated' }))
      navigate(ROUTE_LOGIN)
    } catch (error) {
      if (isAwsException(error)) {
        switch (error.code) {
          case 'CodeMismatchException':
            dispatch(addNotification({ type: 'error', message: 'Too many attempts, try again later' }))
            break
          case 'ExpiredCodeException':
            dispatch(addNotification({ type: 'error', message: 'Your reset code has expired' }))
            break
          case 'InvalidPasswordException':
            dispatch(addNotification({ type: 'error', message: error.message }))
            break
          default:
            dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
            if (isNotifiable(error)) Bugsnag.notify(error)
        }
      } else {
        dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Box>
      <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={formik.handleSubmit}>
        <Typography variant="h5">Verify Reset</Typography>
        <Typography gutterBottom>Please enter the verification code you received via email below.</Typography>
        <TextField
          fullWidth
          id="code"
          name="code"
          label="Password Reset Code"
          margin="normal"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          required />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" gutterBottom>Reset Password</Typography>
        <Typography gutterBottom>Please enter a new password below.</Typography>
        <TextField
          fullWidth
          type="password"
          id="password"
          name="password"
          label="New Password"
          margin="normal"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          required />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Update Password</LoadingButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
        <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
          Back to Sign In
        </Link>
      </Box>
    </Box>
  )
}

export default ResetPassword
