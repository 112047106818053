import { Box } from '@mui/system'

import FlowerLoader from '~/components/Loader/FlowerLoader'

const AppRouteLoader: React.FC = () => {
  return <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <FlowerLoader sx={{ width: '100px', height: '100px' }} />
    </Box>
  </Box>
}

export default AppRouteLoader
