import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'
import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { CONSUMER_TYPE, CONSUMER_TYPES, CUSTOMER_TITLES } from '~/constants/promotionalUser'

interface PersonalInformationProps {
  onNextStep: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  consumerType: yup.string().required('Consumer type is required'),
  company: yup.string().when('consumerType', {
    is: CONSUMER_TYPE.BUSINESS,
    then: yup.string().required('Company is required'),
  }),
  title: yup.string().required('Title is required'),
  firstName: yup.string().max(50, 'First Name should be no longer than 50 characters').required('First Name is required'),
  lastName: yup.string().max(50, 'Last Name should be no longer than 50 characters').required('Last Name is required'),
  addressLineOne: yup.string().max(150, 'Address Line 1 should be no longer than 150 characters').required('Address Line One is required'),
  city: yup.string().max(50, 'City should be no longer than 50 characters').required('City is required'),
  county: yup.string().max(50, 'County should be no longer than 50 characters').required('County is required'),
  postalCode: yup.string().max(10, 'Post Code should be no longer than 10 characters').required('Post Code is required'),
  mobileNo: yup.string().max(20, 'Mobile Number should be no longer than 20 characters').required('Mobile Number is required'),
})

const PersonalInformation: React.FC<PersonalInformationProps> = ({ onNextStep }): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      consumerType: '',
      company: '',
      title: '',
      firstName: '',
      lastName: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      county: '',
      postalCode: '',
      mobileNo: '',
    },
    validationSchema,
    onSubmit: onNextStep,
  })

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">Personal Information</Typography>
      </Box>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Account Type</InputLabel>
          <Select
            fullWidth
            id="consumerType"
            name="consumerType"
            label="Account Type"
            value={formik.values.consumerType}
            onChange={formik.handleChange}
            error={formik.touched.consumerType && Boolean(formik.errors.consumerType)}
            required>
              {CONSUMER_TYPES.map(({ value, label }) => (<MenuItem key={value} value={value}>{label}</MenuItem>))}
          </Select>
          {formik.touched.consumerType && formik.errors.consumerType && <FormHelperText error>{formik.errors.consumerType}</FormHelperText>}
        </FormControl>
        {formik.values.consumerType === CONSUMER_TYPE.BUSINESS && (
          <TextField
            fullWidth
            id="company"
            name="company"
            label="Business Name"
            margin="normal"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
            required />
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Title</InputLabel>
          <Select
            fullWidth
            id="title"
            name="title"
            label="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            required>
              {CUSTOMER_TITLES.map(title => (<MenuItem key={title} value={title}>{title}</MenuItem>))}
          </Select>
          {formik.touched.title && formik.errors.title && <FormHelperText error>{formik.errors.title}</FormHelperText>}
        </FormControl>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          margin="normal"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          required />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          margin="normal"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          required />
        <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
        <Typography variant="h5">Contact Details</Typography>
        <TextField
          fullWidth
          id="addressLineOne"
          name="addressLineOne"
          label="Address Line #1"
          margin="normal"
          value={formik.values.addressLineOne}
          onChange={formik.handleChange}
          error={formik.touched.addressLineOne && Boolean(formik.errors.addressLineOne)}
          helperText={formik.touched.addressLineOne && formik.errors.addressLineOne}
          required />
        <TextField
          fullWidth
          id="addressLineTwo"
          name="addressLineTwo"
          label="Address Line #2"
          margin="normal"
          value={formik.values.addressLineTwo}
          onChange={formik.handleChange}
          error={formik.touched.addressLineTwo && Boolean(formik.errors.addressLineTwo)}
          helperText={formik.touched.addressLineTwo && formik.errors.addressLineTwo} />
        <TextField
          fullWidth
          id="city"
          name="city"
          label="City"
          margin="normal"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          required />
        <TextField
          fullWidth
          id="county"
          name="county"
          label="County"
          margin="normal"
          value={formik.values.county}
          onChange={formik.handleChange}
          error={formik.touched.county && Boolean(formik.errors.county)}
          helperText={formik.touched.county && formik.errors.county}
          required />
        <TextField
          fullWidth
          id="postalCode"
          name="postalCode"
          label="Postcode"
          margin="normal"
          value={formik.values.postalCode}
          onChange={formik.handleChange}
          error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
          helperText={formik.touched.postalCode && formik.errors.postalCode}
          required />
        <TextField
          fullWidth
          id="mobileNo"
          name="mobileNo"
          label="Mobile Number"
          margin="normal"
          value={formik.values.mobileNo}
          onChange={formik.handleChange}
          error={formik.touched.mobileNo && Boolean(formik.errors.mobileNo)}
          helperText={formik.touched.mobileNo && formik.errors.mobileNo}
          required />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}>Next</Button>
      </Box>
    </Box>
  )
}

export default PersonalInformation
