import { Fragment } from 'react'
import { Avatar, Box, Chip, CircularProgress, Divider, List, ListItem, ListItemAvatar, Typography } from '@mui/material'
import { format } from 'date-fns'
import { LoadingButton } from '@mui/lab'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import RedeemIcon from '@mui/icons-material/Redeem'
import CancelIcon from '@mui/icons-material/Cancel'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import StorefrontIcon from '@mui/icons-material/Storefront'

import { PurchaseMethod } from '~/constants/refill'
import { UserClaim, UserClaimPurchaseItem } from '~/types/claim/UserClaim'

import InStoreIcon from '~/assets/images/in-store-icon.svg'
import AtHomeIcon from '~/assets/images/at-home-icon.svg'
import { formatLoyaltyPoints } from '~/helpers/promotionalUser/loyaltyPoints'
import { BrandColor } from '~/config/theme'
import RefillPackIcon from '~/components/Icon/RefillPackIcon'

interface MyRefillsProps {
  claims: UserClaim[]
  end: boolean
  fetched: boolean
  loading: boolean
  onLoadMore: () => Promise<void>
}

const MyRefills: React.FunctionComponent<MyRefillsProps> = ({ claims, fetched, loading, end = true, onLoadMore }) => {
  const getFirstPurchaseItem = (claim: UserClaim): UserClaimPurchaseItem | null => {
    if (claim.purchaseItems) {
      return claim.purchaseItems[0]
    }

    return null
  }

  const getClaimIcon = (status: string): JSX.Element => {
    switch (status) {
      case 'Rejected':
        return <CancelIcon />
      case 'Claimed':
      case 'Processing Payment':
      case ' Dispatching':
        return <CheckCircleIcon />
      default:
        return <PendingIcon />
    }
  }

  const getClaimChipStyle = (status: string): { backgroundColor: string, color: string } => {
    switch (status) {
      case 'Rejected':
        return { backgroundColor: BrandColor.BLACK, color: '#fff' }
      case 'Claimed':
      case 'Processing Payment':
      case ' Dispatching':
        return { backgroundColor: BrandColor.GREEN, color: '#fff' }
      default:
        return { backgroundColor: BrandColor.BLUE, color: '#fff' }
    }
  }

  const handleLoadMore = (): void => {
    void onLoadMore()
  }

  return (
    <Box>
      <Typography variant='h4' gutterBottom>My Refills</Typography>
      <Typography>Here are all your Refills.</Typography>
      {!fetched && loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 4 }}><CircularProgress /></Box>}
      {fetched && !loading && claims.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', py: 4, opacity: 0.5 }}>
        <Avatar src={AtHomeIcon} sx={{ p: 1, mr: 2 }} />
        <Typography variant='body1' gutterBottom>No Refills Found</Typography>
      </Box>}
      <List sx={{ pb: 0 }}>
        {claims.map((claim, index) => (
          <Fragment key={claim.shortId}>
            <ListItem alignItems="flex-start" sx={{ py: 2 }}>
              <ListItemAvatar>
                <Avatar src={claim.purchaseMethod === PurchaseMethod.IN_STORE ? InStoreIcon : AtHomeIcon} sx={{ pr: 1, mr: 2 }} variant="square" />
              </ListItemAvatar>

              <Box sx={{ display: 'flex', width: '100%', alignItems: { xs: 'left', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RefillPackIcon sx={{ mr: 1 }} fontSize="small" />
                    <Typography variant="body1" textOverflow={'ellipsis'}>{(getFirstPurchaseItem(claim))?.name}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StorefrontIcon sx={{ mr: 1 }} fontSize="small" />
                    <Typography variant="body2" color="text.primary" textOverflow={'ellipsis'}>
                      {claim?.qualifyingLocation?.name ?? getFirstPurchaseItem(claim)?.placeOfPurchase}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon sx={{ mr: 1 }} fontSize="small" />
                    <Typography variant="body2" color="text.primary" textOverflow={'ellipsis'}>{format(new Date(claim.createdAt), 'MMMM d, yyyy')}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: { xs: 'flex', sm: 'block' }, ml: { xs: 'none', sm: 'auto' }, mt: { xs: 1, sm: 0 }, textAlign: { xs: 'left', sm: 'right' } }}>
                  <Box sx={{ mb: 1, mr: { xs: 1, sm: 0 } }}><Chip icon={getClaimIcon(claim.status)} label={claim.status} size="small" sx={{ fontWeight: 800, ...getClaimChipStyle(claim.status) }} /></Box>
                  <Chip icon={<RedeemIcon />} label={`${formatLoyaltyPoints(claim?.loyaltyPoints)}`} size="small" />
                </Box>
              </Box>
            </ListItem>
            {index !== claims.length - 1 && <Divider variant="inset" component="li" />}
          </Fragment>
        ))}
      </List>
      <Box sx={{ textAlign: 'center', mt: end ? 4 : 0 }}>
        {!end && <LoadingButton loading={loading} onClick={handleLoadMore}>Load More</LoadingButton>}
      </Box>
    </Box>
  )
}

export default MyRefills
