import { useEffect, useState } from 'react'

const getBrowserVisibilityProperty = (document: Document & { msHidden?: boolean, webkitHidden?: boolean }): 'webkitvisibilitychange' | 'visibilitychange' => {
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }

  return 'visibilitychange'
}

const getBrowserDocumentHiddenProperty = (document: Document & { msHidden?: boolean, webkitHidden?: boolean }): 'msHidden' | 'webkitHidden' | 'hidden' => {
  if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }

  return 'hidden'
}

const getIsDocumentHidden = (document: Document & { msHidden?: boolean, webkitHidden?: boolean }): boolean => {
  return !document?.[getBrowserDocumentHiddenProperty(document)]
}

export const usePageVisibility = (document: Document & { msHidden?: boolean, webkitHidden?: boolean }): boolean => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden(document))

  const onVisibilityChange = (): void => setIsVisible(getIsDocumentHidden(document))

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProperty(document)

    document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  }, [])

  return isVisible
}
