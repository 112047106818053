import { Box, Button, TextField, Typography } from '@mui/material'

import * as yup from 'yup'
import { FormikValues, useFormik } from 'formik'

interface EmailAndPasswordProps {
  onNextStep: (values: FormikValues) => Promise<void>
}

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[a-z]+/, 'Must have at least one lowercase character')
    .matches(/[A-Z]+/, 'Must have at least one uppercase character')
    .matches(/\d+/, 'Must have at least one number')
    .matches(/[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`]+/, 'Password is using an invalid character')
    .required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Password Confirmation is required'),
})

const EmailAndPassword: React.FC<EmailAndPasswordProps> = ({ onNextStep }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: onNextStep,
  })

  return (
    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit} autoComplete="off">
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">Sign Up</Typography>
      </Box>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        margin="normal"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        required />
      <TextField
        fullWidth
        id="password"
        name="password"
        type="password"
        label="Password"
        margin="normal"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        required />
      <TextField
        fullWidth
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        margin="normal"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        required />
      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}>Next</Button>
    </Box>
  )
}

export default EmailAndPassword
