import * as yup from 'yup'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { Box, Breadcrumbs, Divider, Link, TextField, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_ACCOUNT } from '~/routes/Routes'
import React from 'react'

interface PasswordProps {
  isLoading: boolean
  onSave: (values: any) => Promise<void>
}

const validationSchema = yup.object({
  oldPassword: yup.string().required('Current Password is required'),
  newPassword: yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[a-z]+/, 'Must have at least one lowercase character')
    .matches(/[A-Z]+/, 'Must have at least one uppercase character')
    .matches(/\d+/, 'Must have at least one number')
    .matches(/[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`]+/, 'Password is using an invalid character')
    .required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match').required('Password Confirmation is required'),
})

const Password: React.FC<PasswordProps> = ({ isLoading, onSave }): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: onSave,
  })

  return <>
    <Breadcrumbs aria-label="breadcrumb">
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Personal Details</Typography>
    </Breadcrumbs>

    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      <Typography variant="h4" sx={{ my: 2 }}>Change Password</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>In order to change your password your current password is required.</Typography>
      <TextField
        fullWidth
        type="password"
        id="oldPassword"
        name="oldPassword"
        label="Current Password"
        margin="normal"
        value={formik.values.oldPassword}
        onChange={formik.handleChange}
        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
        helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        required />
      <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
      <TextField
        fullWidth
        type="password"
        id="newPassword"
        name="newPassword"
        label="New Password"
        margin="normal"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
        required />
      <TextField
        fullWidth
        type="password"
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm New Password"
        margin="normal"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        required />
      <Box sx={{ display: 'flex', justifyContent: 'right', my: 2 }}>
        <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ width: { xs: '100%', md: 'auto' } }}>Save</LoadingButton>
      </Box>
    </Box>
  </>
}

export default Password
