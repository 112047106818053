import { createSlice } from '@reduxjs/toolkit'

import { CognitoUser } from '~/types/cognitoUser/CognitoUser'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'

import { checkIfAuthenticated, getPromotionalUser, getPromotionalUserMeta, login, signOut } from './actions'

interface AuthState {
  user: CognitoUser | null
  promotionalUser: PromotionalUser | null
  isAuthenticating: boolean
  isAuthenticated: boolean
  lastLoginAt: number | null
  signUp: {
    email: string
    password: string
    title: string
    firstName: string
    lastName: string
    addressLineOne: string
    addressLineTwo?: string
    city: string
    county: string
    postalCode?: string
    mobileNo: string
    consumerType: string
  }
}

const initialState: AuthState = {
  user: null,
  promotionalUser: null,
  isAuthenticating: true,
  isAuthenticated: false,
  lastLoginAt: null,
  signUp: {
    email: '',
    password: '',
    title: '',
    firstName: '',
    lastName: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    county: '',
    postalCode: '',
    mobileNo: '',
    consumerType: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeSignUp: (state, action) => {
      state.signUp = {
        ...state.signUp,
        ...action.payload,
      }
    },
    deductLoyaltyPoints: (state, action) => {
      if (state.promotionalUser?.loyaltyPoints) {
        state.promotionalUser.loyaltyPoints -= action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIfAuthenticated.fulfilled, (state, action) => {
      const { 'custom:userId': userId, ...user } = action.payload

      state.user = {
        ...user,
        userId,
      }
      state.promotionalUser = {
        ...state.promotionalUser,
        userId,
        email: user.email,
        firstName: user.given_name,
      }
      state.isAuthenticating = false
      state.isAuthenticated = true
    })
    builder.addCase(checkIfAuthenticated.rejected, (state, action) => {
      state.user = null
      state.isAuthenticating = false
      state.isAuthenticated = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const { 'custom:userId': userId, ...user } = action.payload

      state.user = {
        ...user,
        userId,
      }
      state.promotionalUser = {
        ...state.promotionalUser,
        userId,
        email: user.email,
        firstName: user.given_name,
      }
      state.lastLoginAt = (new Date()).getSeconds()
      state.isAuthenticating = false
      state.isAuthenticated = true
    })
    builder.addCase(signOut.fulfilled, (state, action) => {
      state.user = null
      state.isAuthenticating = false
      state.isAuthenticated = false
    })
    builder.addCase(getPromotionalUser.fulfilled, (state, action) => {
      state.promotionalUser = action.payload
    })
    builder.addCase(getPromotionalUserMeta.fulfilled, (state, action) => {
      state.promotionalUser = {
        ...state.promotionalUser,
        ...action.payload,
      }
    })
  },
})

export const { deductLoyaltyPoints, storeSignUp } = authSlice.actions

export default authSlice.reducer
