export enum EnvironmentalImpactMaterial {
  Plastic = 'plastic',
  Carbon = 'carbon'
}

export interface EnvironmentalImpact {
  material?: {
    [key in EnvironmentalImpactMaterial]: number
  }
  footprint?: {
    [key in EnvironmentalImpactMaterial]: number
  }
  reduction?: {
    [key in EnvironmentalImpactMaterial]: number
  }
}
