import { AppBar, Box, Container, IconButton, Link, Toolbar, Tooltip } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { useSelector } from 'react-redux'

import { RootState } from '~/redux/store'
import { ROUTE_ACCOUNT, ROUTE_HOME } from '~/routes/Routes'

import RefillLogo from '~/assets/images/refill-logo.svg'

const PublicPage: React.FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  return <Container component="main" maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '300px' }}>
    <AppBar elevation={0} position='static'>
      <Container maxWidth='xl' disableGutters>
        <Toolbar disableGutters sx={{ pt: 1 }}>
          <Link component={RouterLink} to={ROUTE_HOME}>
            <Box
              component="img"
              sx={{
                height: 45,
                mr: 2,
              }}
              alt="Ecover Loyalty"
              src={RefillLogo}
            />
          </Link>
          {isAuthenticated && <Box sx={{ ml: 'auto' }}>
            <Tooltip title="View Account">
              <IconButton component={RouterLink} to={ROUTE_ACCOUNT}>
                <PersonIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
    <Box sx={{ py: 4 }}>
      <Outlet />
    </Box>
  </Container>
}

export default PublicPage
