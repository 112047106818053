import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useVersionCheck } from '~/hooks/useVersionCheck'

const OnRouteChange = (): null => {
  const location = useLocation()
  const path = useRef(location.pathname)
  const isMostRecentVersion = useVersionCheck()

  /**
   * Clear the cache to ensure no remnants of the previoous build remain
   */
  const clearAllLocalCacheKeys = async (): Promise<void> => {
    const cacheKeys = await caches.keys()
    await Promise.all(cacheKeys.map(async (key) => await caches.delete(key)))
  }

  const checkVersion = async (): Promise<void> => {
    if (!isMostRecentVersion && location.pathname !== path.current) {
      await clearAllLocalCacheKeys()
      return window.location.replace(location.pathname)
    }
  }

  const scrollToTop = (): void => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    void checkVersion()
    scrollToTop()

    path.current = location.pathname
  }, [location.pathname])

  return null
}

export default OnRouteChange
