import { TermsTableConfig } from '~/types/views/terms/TermsTableConfig'

export const inStoreTable: TermsTableConfig = {
  titles: [
    'SKU',
    'DESCRIPTION',
    'CATEGORY',
    'Purchases made between 1st October 2022- 10th July 2023',
    'Purchases made between 11th July 2023 - 31st July 2023',
  ],
  rows: [
    {
      name: 'Dilute Laundry Liquid - 1.5L',
      cells: [
        'In-Store',
        'Dilute Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'All-Purpose Cleaner - 1L',
      cells: [
        'In-Store',
        'All-Purpose Cleaner - 1L',
        'HOME',
        '£0.21',
        '£0.40',
      ],
    },
    {
      name: 'All-Purpose Cleaner - 5L',
      cells: [
        'In-Store',
        'All-Purpose Cleaner - 5L',
        'HOME',
        '£1.15',
        '£2.30',
      ],
    },
    {
      name: 'Washing Up Liquid - 950ml',
      cells: [
        'In-Store',
        'Washing Up Liquid - 950ml',
        'DISH',
        '£0.21',
        '£0.41',
      ],
    },
    {
      name: 'Washing Up Liquid - 450ml',
      cells: [
        'In-Store',
        'Washing Up Liquid - 450ml',
        'DISH',
        '£0.15',
        '£0.29',
      ],
    },
    {
      name: 'Fabric Softener - 1.5L',
      cells: [
        'In-Store',
        'Fabric Softener - 1.5L',
        'LAUNDRY',
        '£0.30',
        '£0.60',
      ],
    },
    {
      name: 'Fabric Softener - 750ml',
      cells: [
        'In-Store',
        'Fabric Softener - 750ml',
        'LAUNDRY',
        '£0.16',
        '£0.32',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 1L',
      cells: [
        'In-Store',
        'Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 1.5L',
      cells: [
        'In-Store',
        'Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 5L',
      cells: [
        'In-Store',
        'Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 1L',
      cells: [
        'In-Store',
        'Bio Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 1.5L',
      cells: [
        'In-Store',
        'Bio Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 5L',
      cells: [
        'In-Store',
        'Bio Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 1L',
      cells: [
        'In-Store',
        'Non-Bio Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 1.5L',
      cells: [
        'In-Store',
        'Non-Bio Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 5L',
      cells: [
        'In-Store',
        'Non-Bio Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Hand Soap - 5L',
      cells: [
        'In-Store',
        'Hand Soap - 5L',
        'PERSONAL CARE',
        '£2.90',
        '£5.80',
      ],
    },
    {
      name: 'Laundry Liquid - 5L',
      cells: [
        'In-Store',
        'Laundry Liquid - 5L',
        'LAUNDRY',
        '£1.97',
        '£3.94',
      ],
    },
    {
      name: 'Washing Up Liquid - 5L',
      cells: [
        'In-Store',
        'Washing Up Liquid - 5L',
        'DISH',
        '£1.10',
        '£2.20',
      ],
    },
    {
      name: 'Toilet Cleaner - 5L',
      cells: [
        'In-Store',
        'Toilet Cleaner - 5L',
        'HOME',
        '£1.22',
        '£2.44',
      ],
    },
    {
      name: 'Floor Soap - 5L',
      cells: [
        'In-Store',
        'Floor Soap - 5L',
        'HOME',
        '£1.60',
        '£3.20',
      ],
    },
    {
      name: 'Fabric Softener - 5L',
      cells: [
        'In-Store',
        'Fabric Softener - 5L',
        'LAUNDRY',
        '£1.10',
        '£2.20',
      ],
    },
    {
      name: 'Delicate Concentrated Laundry Liquid - 5L',
      cells: [
        'In-Store',
        'Delicate Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.00',
        '£4.00',
      ],
    },
    {
      name: 'Zero Washing Up Liquid - 5L',
      cells: [
        'In-Store',
        'Zero Washing Up Liquid - 5L',
        'DISH',
        '£1.30',
        '£2.60',
      ],
    },
  ],
}

export const atHomeTable: TermsTableConfig = {
  titles: [
    'SKU',
    'DESCRIPTION',
    'CATEGORY',
    'Purchases made between 1st October 2022- 11th July 2023',
    'Purchases made between 11th July 2023 - 31st July 2022',
  ],
  rows: [
    {
      name: 'Multi-Action Cleaner Concentrated Refill - x2 Box',
      cells: [
        'At Home',
        'Multi-Action Cleaner Concentrated Refill - x2 Box',
        'HOME',
        '£1.00',
        '£2.00',
      ],
    },
    {
      name: 'Multi-Action Cleaner Concentrated Refill',
      cells: [
        'At Home',
        'Multi-Action Cleaner Concentrated Refill',
        'HOME',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'All Purpose Cleaner - 1L',
      cells: [
        'At Home',
        'All Purpose Cleaner - 1L',
        'HOME',
        '£0.21',
        '£0.41',
      ],
    },
    {
      name: 'All Purpose Cleaner - 5L',
      cells: [
        'At Home',
        'All Purpose Cleaner - 5L',
        'HOME',
        '£1.15',
        '£2.30',
      ],
    },
    {
      name: 'All Purpose Cleaner - 15L',
      cells: [
        'At Home',
        'All Purpose Cleaner - 15L',
        'HOME',
        '£2.01',
        '£4.02',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 1L',
      cells: [
        'At Home',
        'Bio Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 1.5L',
      cells: [
        'At Home',
        'Bio Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Bio Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Bio Concentrated Laundry Liquid - 15L',
      cells: [
        'At Home',
        'Bio Concentrated Laundry Liquid - 15L',
        'LAUNDRY',
        '£5.15',
        '£10.30',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 1L',
      cells: [
        'At Home',
        'Non-Bio Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 1.5L',
      cells: [
        'At Home',
        'Non-Bio Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Non-Bio Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Non-Bio Concentrated Laundry Liquid - 15L',
      cells: [
        'At Home',
        'Non-Bio Concentrated Laundry Liquid - 15L',
        'LAUNDRY',
        '£5.15',
        '£10.30',
      ],
    },
    {
      name: 'Colour Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Colour Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 1L',
      cells: [
        'At Home',
        'Concentrated Laundry Liquid - 1L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 1.5L',
      cells: [
        'At Home',
        'Concentrated Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.80',
        '£1.60',
      ],
    },
    {
      name: 'Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Zero Washing Up Liquid - 5L',
      cells: [
        'At Home',
        'Zero Washing Up Liquid - 5L',
        'DISH',
        '£1.30',
        '£2.60',
      ],
    },
    {
      name: 'Zero Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Zero Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.80',
        '£5.60',
      ],
    },
    {
      name: 'Zero Concentrated Laundry Liquid - 15L',
      cells: [
        'At Home',
        'Zero Concentrated Laundry Liquid - 15L',
        'LAUNDRY',
        '£5.15',
        '£10.30',
      ],
    },
    {
      name: 'Delicate Concentrated Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Delicate Concentrated Laundry Liquid - 5L',
        'LAUNDRY',
        '£2.00',
        '£4.00',
      ],
    },
    {
      name: 'Dilute Laundry Liquid - 1.5L',
      cells: [
        'At Home',
        'Dilute Laundry Liquid - 1.5L',
        'LAUNDRY',
        '£0.50',
        '£1.00',
      ],
    },
    {
      name: 'Fabric Softener - 750mL',
      cells: [
        'At Home',
        'Fabric Softener - 750mL',
        'LAUNDRY',
        '£0.16',
        '£0.32',
      ],
    },
    {
      name: 'Fabric Softener - 1.5L',
      cells: [
        'At Home',
        'Fabric Softener - 1.5L',
        'LAUNDRY',
        '£0.30',
        '£0.60',
      ],
    },
    {
      name: 'Fabric Softener - 5L',
      cells: [
        'At Home',
        'Fabric Softener - 5L',
        'LAUNDRY',
        '£1.10',
        '£2.20',
      ],
    },
    {
      name: 'Fabric Softener - 15L',
      cells: [
        'At Home',
        'Fabric Softener - 15L',
        'LAUNDRY',
        '£1.90',
        '£3.80',
      ],
    },
    {
      name: 'Floor Soap - 5L',
      cells: [
        'At Home',
        'Floor Soap - 5L',
        'HOME',
        '£1.60',
        '£3.20',
      ],
    },
    {
      name: 'Hand Soap - 5L',
      cells: [
        'Personal Care',
        'Hand Soap - 5L',
        'HOME',
        '£2.90',
        '£5.80',
      ],
    },
    {
      name: 'Laundry Liquid - 5L',
      cells: [
        'At Home',
        'Laundry Liquid - 5L',
        'LAUNDRY',
        '£1.97',
        '£3.94',
      ],
    },
    {
      name: 'Toilet Cleaner - 5L',
      cells: [
        'At Home',
        'Toilet Cleaner - 5L',
        'HOME',
        '£1.22',
        '£2.44',
      ],
    },
    {
      name: 'Washing Up Liquid - 450mL',
      cells: [
        'At Home',
        'Washing Up Liquid - 450mL',
        'DISH',
        '£0.15',
        '£0.30',
      ],
    },
    {
      name: 'Washing Up Liquid - 950mL',
      cells: [
        'At Home',
        'Washing Up Liquid - 950mL',
        'DISH',
        '£0.21',
        '£0.42',
      ],
    },
    {
      name: 'Washing Up Liquid - 5L',
      cells: [
        'At Home',
        'Washing Up Liquid - 5L',
        'DISH',
        '£1.10',
        '£2.20',
      ],
    },
    {
      name: 'Washing Up Liquid - 15L',
      cells: [
        'At Home',
        'Washing Up Liquid - 15L',
        'DISH',
        '£2.03',
        '£4.06',
      ],
    },
  ],
}
