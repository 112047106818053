export enum PurchaseMethod {
  AT_HOME = 'At Home',
  IN_STORE = 'In Store',
}

export enum RefillStep {
  TYPE = 'type',
  LOCATION_SERVICE = 'location-service',
  CHECKLIST = 'checklist',
  PURCHASE = 'purchase',
  SUPPORTING_DOCUMENT = 'supporting-document',
  PRODUCT = 'product',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

export enum SupportingDocument {
  PROOF_OF_PURCHASE = 'proofOfPurchase',
  PROOF_OF_PRODUCT = 'proofOfProduct',
}

export enum SupportingDocumentAccess {
  CAMERA = 'camera',
  FILE = 'file',
}

export const AT_HOME_RESELLERS = [
  'Abel & Cole',
  'Acorn Pharmacy',
  'Alberts Hardware',
  'Almondsbury Community Shop',
  'Amazon',
  'Amy\'s Housewares',
  'Annie\'s Naturally',
  'Anster Deli',
  'Aphrodite Health Food Shop',
  'Apothecary 27',
  'Apple N Orange',
  'Archie Browns',
  'Arsenal Food & Wine',
  'As Nature Intended Balham',
  'At Altuni\'s',
  'At Down To Earth',
  'Austen\'s Home Hardware',
  'B Healthy',
  'Baden House Pharmacy',
  'Bailey\'s County Store',
  'Baker\'s DIY',
  'Bathford Village Shop',
  'Bawtry Natural Health',
  'Beanfreaks',
  'Beeches of Walkley',
  'Beneficial Health',
  'Ben\'s Farmshop',
  'Best Health Food Shop',
  'Big Green Smile',
  'Birkdale Health Store',
  'Blagdon Stores',
  'Blisland Post Office & Stores',
  'Blue Corn',
  'Booths',
  'Booths, Garstang',
  'Bosavern Community Farm Shop',
  'Breckles Wholefoods',
  'Brightwell Village Stores',
  'Broad Bean Wholefoods',
  'Brockley Stores',
  'Brockweir And Hewelsfield Village Shop',
  'Broughgammon Farm',
  'Browns Hardware',
  'Brundall Home Hardware',
  'Bruton Wholefoods - Community Co-Operative',
  'Brutons Home Hardware',
  'Budgens',
  'Budgens Holt',
  'Bumblebee Natural Foods',
  'Bunners',
  'Buy Wholefoods Online',
  'Bwyd Y Byd Wholefoods',
  'BYGRAM',
  'Cadfans Of Tywyn',
  'Cafe U',
  'Caley\'s Apothecary',
  'Cam Home & Garden',
  'Campbell Wharf Marina',
  'Canterbury Wholefoods',
  'Carbis Bay Pharmacy',
  'Carrot & Wine Stores',
  'Cattistock Stores',
  'Cave and Castle',
  'Caxton Décor',
  'Ceres Health Food Store',
  'Ceres Natural Foods',
  'Chalfont Home &Hobby Store',
  'Charlies',
  'Chelsea Road Greengrocers',
  'Cherry Tree Organics',
  'Chilbolton Village Shop',
  'Choice Cuts',
  'Christine\'s Sustainable Supermarket',
  'Christow Stores',
  'Chrysalis Wholefoods Shop',
  'Chudleigh DIY',
  'Church Fenton Community Shop',
  'Churchill Post Office and Stores',
  'Claybrooke Mill',
  'Claytons Herbalist ',
  'Clean Direct ',
  'Clifton Hampden Po & Stores',
  'Cloughs Deli',
  'Coastal Pharmacy',
  'Cobbs Wholefoods',
  'Cochranes Home Hardware Ayr',
  'COGS of Coventry',
  'Colerne',
  'Communitea Cafe',
  'Complete Health At The Millstone',
  'Coombe Bissett Stores',
  'Cornerweighs',
  'Costcutter',
  'Country Foods',
  'Country Kitchen',
  'Cousin Norman\'s',
  'Cranbrook Farm Shop & Nursery',
  'Crescent Supermarket',
  'Crockshop & Ralphs Health',
  'Cross Lanes Organic Farm Shop',
  'Crossey\'s Home and Garden',
  'Croxley Hardware',
  'Cusgarne Organic Farm',
  'Cutlacks',
  'Dads Shop',
  'Dapper and Green',
  'Darlings Natural Health',
  'David Neill Mica',
  'Davis DIY',
  'Deli X',
  'Deli-Bazaar',
  'Detling Community Store',
  'Dickiebird Homestore',
  'Down To Earth',
  'Draycott Community Shop',
  'Dryslwyn Community shop',
  'Dubleaus',
  'Ducks Farm Shop',
  'Duffys Home Hardware',
  'D\'yencourt',
  'Dyfi Wholefoods',
  'Earth Matters',
  'Earth Natural Foods',
  'Earthfare',
  'Earthstore Saltburn',
  'Easy Weigh',
  'Eat17',
  'Eco Health Store',
  'Eco Natural Products',
  'Ecover Direct',
  'Edinburgh',
  'Elf Foods',
  'Elixir Health Foods',
  'Emsworth Home Hardware',
  'Entertaining Elephants',
  'Essence at the Health Store',
  'Evans Bros',
  'Exe Valley farm shop',
  'Fair Do\'s',
  'Fairfield Farm College',
  'Farleigh Road Farm Shop',
  'Farm Hill',
  'Farmborough Community Shop',
  'Farringtons Farm Shop',
  'Feckenham Community Shop - The Village Shop',
  'Feeds & Stoves',
  'Fieldseat',
  'Finchingfield Post Office',
  'Fine Fettle Health Foods',
  'Fink',
  'Fishers Home Hardware',
  'Fiveboy\'s',
  'Fodder',
  'Food Fanatics',
  'Food for Living',
  'For Goodness Sake',
  'Forest Friendly',
  'Framlingham Co-op',
  'Fred\'s DIY',
  'Frome Wholefoods',
  'Fruits Of The Earth',
  'FSC Millport',
  'Full of Beans',
  'Futurefarms Martin',
  'G.D Parker &Co',
  'Ganesha Wholefoods',
  'Gardiner Haskins',
  'Garlands Organic',
  'Gold Hill Organic Farm',
  'Good Health',
  'Goodness Health',
  'Goring Hardware',
  'Gosport Health Foods',
  'Graffham Village Shop',
  'Grain Wholefood',
  'Grain2Gain',
  'Graines & Green',
  'Grampian Health Store',
  'Grandvilles',
  'Grange Farm Shop',
  'Grantham Health Store',
  'Grasshopper Toys',
  'Green & Blue',
  'Green & Flavour',
  'Green & Glory',
  'Green Dreaming',
  'Green Ginger',
  'Green Island Wholefoods',
  'Green Shop - Environment Centre',
  'Green Valley Yurts',
  'Greenfields',
  'Green-Jay',
  'Greenlands Health Store',
  'Greenlife',
  'Greens Health Foods',
  'Greens On The Hill',
  'Griffins Yard',
  'Gwilliams of Edington ltd',
  'H R Hardware',
  'Hackney Fresh',
  'Haelan Centre',
  'Hafod Hardware',
  'Half Moon Healthfoods',
  'Handcross Hardware and Craft',
  'Handy Hardware',
  'Hansa Wholefoods',
  'Happy Apple Totnes',
  'Harbertonford Post Office',
  'Harringay Local Store',
  'Harvest Health Store',
  'Harvest Moon',
  'Harvest Natural Foods',
  'Hawkesbury Stores',
  'Hay Deli',
  'Headington Fairtrade',
  'Health & Food Llanrwst',
  'Health Conscious',
  'Health Emporium',
  'Health Foods For You',
  'Health On The Hill',
  'Health Right',
  'Healthfood',
  'Healthfoods Unlimited',
  'Healthright',
  'Healthy and Wise',
  'Healthy Pulses',
  'Healthy Stuff',
  'HEALTHYISH',
  'Heard and Sendell',
  'Heaven and Earth Health Foods',
  'HelloRefill',
  'Hendra Health Store',
  'High Street Organics',
  'Highland Health Stores',
  'Highland Wholefood Workers Cooperative',
  'Hogan\'s Fine Food Company',
  'Holbeach Wholefoods',
  'Hollingsworths Store',
  'Home Hub',
  'Homecare Essentials',
  'Homeleigh Garden Centre',
  'Horsley Community Shop',
  'Houseproud Of Morningside',
  'Hoye\'s Home & Garden',
  'Hungate Health Store',
  'Hunts Supermarket',
  'ILocal',
  'Islands Homehardware',
  'It\'s All About You Eco',
  'Ivy House',
  'Iwerne Minster Village Stores',
  'Jacksons Of Moira',
  'Jacksons of Saintfield',
  'Java and Chai Tea Room',
  'Jay\'s Budgens',
  'John Lyne Home Hardware',
  'Johnsons General Store',
  'Jon Thorner\'s Farm Shop',
  'Jones Home Hardware',
  'Jordans Village Community Store',
  'Jo\'s Organic Stores',
  'Just1swap',
  'K J Gifts Stop &Shop',
  'Karine Jackson',
  'Kennerleigh Community Shop',
  'Kensal Green Grocery',
  'Kilworthy Kapers',
  'Kitty\'s Launderette',
  'Kyffins Health Foods',
  'La Amazonas',
  'Lansdown Health Foods',
  'Lansdown Health Store',
  'Larkhall Farm Shop',
  'Laundry@55',
  'Lawn farm shop',
  'Lawson\'s',
  'Lechyd',
  'Ledbury Hardware',
  'Lemon Fresh Laundry',
  'Levens Village Shop',
  'Lifestyle Health Store',
  'Loch Arthur Farm Shop',
  'Lock Down Larder',
  'Londis',
  'Long Ashton Post Office',
  'Loop-e Architectural Design',
  'Lorton Village Shop',
  'Love Ecover',
  'LucyTom Interiors',
  'Ludwell Post Office &Stores',
  'Lympsham Village Shop',
  'Malletts Home Hardware',
  'Marple Health',
  'Maunder &Sons',
  'Maxis Naked Foods',
  'Mays Country Store',
  'McIIveen Spara',
  'Mells Village Shop',
  'Michael Thomas Family Butchers',
  'Mill on the Brue',
  'Modbury Farm Shop',
  'Mole Avon',
  'Mole Avon Country Stores ',
  'Mother Earth',
  'Mother Earth Health Foods',
  'Mother Nature Health & Herbal',
  'Mulberry Bush Wholefoods',
  'Mum\'s Whole Food',
  'My Little Eco',
  'Myriad Organics',
  'Nantgwynfaen Farm Shop',
  'Natural Foodstore Co-operative',
  'Natural Health',
  'Natural Health Pharmacy',
  'Natural Store',
  'Naturalife Wholefoods',
  'Naturally Organic Wholefoods',
  'Nature\'s Choice',
  'Nature\'s Choice Health Food',
  'Natures Corner',
  'Nature\'s Harvest',
  'Natures Health Store',
  'Natures Larder',
  'Natures Way',
  'Naturetrail',
  'New Galloway Community Shop',
  'Newleaf Healthfoods',
  'Newton Dee Store',
  'Nicholsons Emporium',
  'Nil Living',
  'Nirvana Health',
  'Nisbets',
  'Noah\'s Health Food Store',
  'North Lakes Healthstore & Clinic',
  'Northiam DIY & Garden',
  'Nourish',
  'Noyces',
  'Number 25 Delicatessen',
  'Nurtured by Nature',
  'Nutrition Centre',
  'Oakville Care Centre',
  'Oats',
  'Orchards Farm Shop',
  'Organic &Wholefoods Leominster',
  'Organic Deli Cafe &Wholefoods Store',
  'Organic Grocery',
  'Organic Health',
  'Organic Hill',
  'Organic Nest',
  'Organic Planet Valley',
  'Organic Village Market',
  'Organically Speaking',
  'Organico',
  'Organico West Hampstead',
  'Out of Eden',
  'Palm Springs',
  'Parker and Son Home Hardware',
  'Parkers Of Ditchling',
  'Paull & Co',
  'Peckham Organics',
  'Pennyfields',
  'Peppercorn',
  'Peppermint Beach',
  'Peppers Cafe Deli & Wholefoods',
  'Perry Court Farm Shop',
  'Peters Health Products',
  'Pete\'s Farm Run',
  'Pigney & Son\'s',
  'Pipoca Vegan',
  'Planet Health and Organics',
  'Planet Organic',
  'Plank\'s Farm Shop',
  'Polbeth and West Calder Community Garden Refill Station (Garden For Life)',
  'Polstead Community Shop',
  'Poppadums Healthfoods',
  'Poppy\'s Pantry',
  'Portobello Wholefoods',
  'Purbeck Wholefoods',
  'Pure Health',
  'Purleigh Community Shop',
  'Quatt Farm Shop & Café',
  'R W MORTON',
  'Rainbow Apothecary',
  'Rainbow Wholefoods',
  'Rankins Supermarket',
  'Raw Bar',
  'Real Foods',
  'Reapers Healthfoods',
  'Regent\'s Natural Limited',
  'Renee\'s Health Food Shop',
  'Reno Wholefoods',
  'Ringden farm',
  'Riverside Health',
  'Robert Dyas',
  'Rocks Locks',
  'Rode General Store',
  'Romsey Home Hardware',
  'Roots Family Farm Shop',
  'Roots Market',
  'Ropley Courtyard Village Shop & Post Office',
  'Route Zero @ Chalke House Emporium',
  'Route Zero @ Imagiplay',
  'Route Zero @ Sprout & Flower',
  'Rowlands Castle Home Hardware',
  'Rural Trading',
  'Rushlake Green Village Stores',
  'Ruthin Wholefoods',
  'Rye Health Store',
  'Sage\'s Health Store',
  'Salix Health And WellBeing Centre',
  'Sarratt Post Office Stores',
  'Savages Ltd',
  'Scoltocks Natural Foods',
  'Scoop & Spice',
  'Seaford Health Store',
  'Seasons Exeter',
  'Serenity Bean',
  'Sg Manning Pharmacy',
  'Shepperton Homecare',
  'Sherwood Wholefoods',
  'Shiny Goodness',
  'Shop Ellis',
  'Shrewsbury Herbarium',
  'Silver Lane Wholefoods',
  'Silverman Chemist',
  'Simply Fresh Bethnal Green',
  'Siop Dewi',
  'Skateraw Store',
  'Southsea Health Shop',
  'Southville Deli',
  'Spar Menheniot ',
  'Spar Stores',
  'Spice of Life',
  'Spill The Beans',
  'Spirit of Nature',
  'Square Farm Shop',
  'Squirrel Stores Ltd',
  'St Agnes Spar',
  'Staines &Brights',
  'Steyning Store and Post Office',
  'Stones Fruit &Veg',
  'Stourbridge Health Store',
  'Stratton Stores',
  'Streets Ironmongers',
  'Summit Bakery &Wholefoods',
  'Sunnyfields Organic Farm',
  'Sunrise Natural Health Centre',
  'Sunseed (Naturalife Wholefoods) Wellington',
  'Surbiton Natural Health Centre',
  'Sylvania Stores',
  'Tagon Stores',
  'Taurus Crafts Gift Shop',
  'The Ark',
  'The Baytree',
  'The Boathouse Restaraunt & Shop',
  'The Bounds Green Organic Natural Foods',
  'The Courtyard',
  'The De Beauvoir Wholefoods',
  'The ECO Shop',
  'The Ecover Refillery',
  'The Farm Shop',
  'The Farm Shop At The Mart',
  'The Final Checkout',
  'The Flowers Station',
  'The Fruit Basket',
  'The Ginger Piggery and Ginny\'s Cafe',
  'The Good Earth',
  'The Grain Grocer',
  'The Green Basket',
  'The Green Bean',
  'The Green Grocers',
  'The Green House',
  'The Green Life Pantry',
  'The Green Shop',
  'The Grocer on Church Street',
  'The Health Shop',
  'The Health Store',
  'The Healthy Life Co',
  'The Hide',
  'The Kensal store',
  'The Langport Stores',
  'The Little Farm Barn',
  'The Little Grocer',
  'The Lockdown Larder',
  'The Natural Choice',
  'The Natural Food Company',
  'The Natural Health Shop',
  'The Natural Store',
  'The Natural Way',
  'The New Broom',
  'The Only Weigh Out',
  'The Organic Food Store',
  'The Postal Pantry Co',
  'The Real Food Company',
  'The Refill Hut',
  'The Refill Jar',
  'The Seed Wholefood Store and Café',
  'The Selkie Collective',
  'The shop at Bromsberrow',
  'The Simple Weigh',
  'The Smoke Fired Whole Foods Shop',
  'The Society Company',
  'The Super Store',
  'The Sustainable Shed',
  'The Udder Farm Shop',
  'The Useless Shop',
  'The Village Shop',
  'The Wool Sanctuary',
  'Thorntons Budgens Belsize Park',
  'Thoughtful Living',
  'Timber',
  'Tindall\'s Home Hardware',
  'Tisbury Supplies',
  'Tonys Continental',
  'Tordown Healing Centre',
  'Toucan Wholefoods',
  'Tree Top Press Farm Shop',
  'Twee Ltd',
  'Two Peas In A Pod',
  'Twyford Post Office and Stores',
  'Tÿ· Cemaes',
  'Unboxed Kent',
  'Uncle Sid\'s Zero Waste Store',
  'Upton Home Hardware',
  'Urban Native Organic & Wholefoods',
  'V&P Collins Farm Shop',
  'Vans Good Food Shop',
  'Veg Hut - Healthy Organic Boutique',
  'Vegan Goodies Wales',
  'Victoria Pharmacy',
  'Viking Direct UK',
  'Village Organic',
  'W. Lawrence Taylor',
  'Waitrose',
  'Waitrose & Partners',
  'Walter Bailey Par',
  'Washwalk Farm, Blackawton',
  'Waste Not Want Not',
  'Watson Home Hardware',
  'Wealden Wholefoods Cooperative',
  'Webb\'s Ironmongery Stores',
  'Weigh and Pay',
  'Well-I-Hole Farm Shop',
  'Wellness District',
  'Wellow Parlour Shop',
  'West Meon Village Shop',
  'White Mills Marina',
  'Wholefoods',
  'Wholefoods and Healing',
  'Wholefoods Of Newport',
  'Widdicombe\'s',
  'Wild & Free',
  'Wild and Fresh Organics',
  'Wild Honey',
  'Wild Oats',
  'Wild Origins',
  'Wildmoor Fine Food and Drink',
  'Wilton Hardware',
  'Winford Community Shop',
  'Wookey Hub Cafe & Village Shop',
  'Worth Stores and Tea Shop',
  'Wren\'s Shop',
  'Your Nuts Wholefoods',
  'Zeds Wholefoods',
  'Zest Organic Food',
]
