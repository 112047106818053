import { Box, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import { Helmet } from 'react-helmet-async'

import OopsNotFound from '~/assets/images/404.svg'
import { BrandColor } from '~/config/theme'

const NotFound: React.FC = () => {
  return <>
    <Helmet>
      <title>Not Found | Ecover Loyalty Rewards</title>
    </Helmet>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 40px)', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', flexGrow: 1 }}>
        <Box component="img" src={OopsNotFound} alt="Oops, not found" sx={{ height: '100%', flexGrow: 1, flexShrink: 1, maxHeight: '500px', maxWidth: '100%' }} />
      </Box>
      <Box sx={{ flexShrink: 0, textAlign: 'center' }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h1" color={BrandColor.BLUE}>Oops!</Typography>
          <Typography variant="body1" color={BrandColor.BLACK}>We can't seem to find the page you are looking for.</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Link component={RouterLink} to="/" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HomeIcon /> Go Home
          </Link>
        </Box>
      </Box>
    </Box>
  </>
}

export default NotFound
