import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, SxProps } from '@mui/material'

import { TermsTableConfig } from '~/types/views/terms/TermsTableConfig'

interface TermsTableProps {
  tableConfig: TermsTableConfig
  style?: {
    TableCell?: SxProps
  }
}

export const TermsTable: React.FC<TermsTableProps> = ({ tableConfig, style }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableConfig.titles.map((title: string, index: number) => (
              <TableCell key={index} sx={{
                width: index === 1 ? 400 : 100,
                ...style?.TableCell,
              }}>
                <strong>{title}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableConfig.rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.cells.map((cell: string, index) => (
                <TableCell key={index} sx={{ ...style?.TableCell }}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
