import { Auth } from 'aws-amplify'
import { FormikValues } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import RequestPasswordReset from './RequestPasswordReset'
import ResetPassword from './ResetPassword'

const ForgotPasswordView: React.FC = () => {
  const [resetEmail, setResetEmail] = useState<string>('')
  const [step, setStep] = useState<'request' | 'reset'>('request')

  const requestPasswordReset = async (values: FormikValues): Promise<void> => {
    const { email } = values

    await Auth.forgotPassword(email)

    setResetEmail(email)
    setStep('reset')
  }

  const resetPassword = async (values: FormikValues): Promise<void> => {
    const { code, password } = values

    await Auth.forgotPasswordSubmit(resetEmail, code, password)
  }

  const renderStep = (): JSX.Element => {
    switch (step) {
      case 'request':
        return <RequestPasswordReset onRequestReset={requestPasswordReset} />
      case 'reset':
        return <ResetPassword onResetPassword={resetPassword} />
    }
  }

  return <>
    <Helmet>
      <title>Forgot Password | Ecover Loyalty Rewards</title>
    </Helmet>
    {renderStep()}
  </>
}

export default ForgotPasswordView
