import { Money } from '@metrisk/money'

import { promotion } from '~/config/promotion'

export const formatLoyaltyPoints = (points?: number, amountOnly = false): string => {
  const loyaltyPoints = points ?? 0

  const money = new Money(loyaltyPoints, promotion.currency)

  return amountOnly ? money.toDecimal().toFixed(2) : money.toLocaleString()
}
