import { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PasswordIcon from '@mui/icons-material/Password'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import HelpIcon from '@mui/icons-material/Help'
import FAQIcon from '@mui/icons-material/LiveHelp'
import TermsIcon from '@mui/icons-material/Gavel'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import CookieIcon from '@mui/icons-material/Cookie'
import { Link as RouterLink } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'

import HowItWorks from '~/components/HowItWorks/HowItWorks'
import { LINK_PRIVACY_POLICY } from '~/constants/externalLinks'
import { getUserReduction } from '~/helpers/promotionalUser/getUserReduction'
import { formatLoyaltyPoints } from '~/helpers/promotionalUser/loyaltyPoints'
import { ROUTE_ACCOUNT_DETAILS, ROUTE_ACCOUNT_PASSWORD, ROUTE_FAQS, ROUTE_ACCOUNT_LOCATION_SERVICES, ROUTE_TERMS } from '~/routes/Routes'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { EnvironmentalImpactMaterial } from '~/types/common/EnvironmentalImpact'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'

import RewardsTotal from '~/assets/images/rewards-total.svg'
import RefillsTotal from '~/assets/images/refills-total.svg'
import PlasticSaved from '~/assets/images/plastic-saved.svg'

interface AccountProps {
  user: PromotionalUser | null
  onFetchPromotionalUserMeta: () => Promise<void>
  signOut: () => void
}

const Account: React.FC<AccountProps> = ({ user, onFetchPromotionalUserMeta, signOut }): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchUserMeta()
  }, [])

  const fetchUserMeta = async (): Promise<void> => {
    try {
      await onFetchPromotionalUserMeta()
      setLoading(false)
    } catch (error) {
      if (!isAbortError(error)) {
        setLoading(false)
        dispatch(addNotification({ type: 'error', message: 'Failed to load updated user information' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const handleIub = (): void => {
    // @ts-expect-error
    window?._iub?.cs?.ui?.openPreferences()
  }

  return <>
    <Typography variant='h4'>Account</Typography>

    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 4 }}>
      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Box component='img' src={RewardsTotal} sx={{ mb: 2 }} />
        <Typography variant='h5' gutterBottom>{!loading && user ? formatLoyaltyPoints(user.loyaltyPoints) : <CircularProgress size={25} />}</Typography>
        <Typography variant='body1' gutterBottom sx={{ textTransform: 'uppercase' }}>Current<br/>Balance</Typography>
      </Box>
      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Box component='img' src={RefillsTotal} sx={{ mb: 2 }} />
        <Typography variant='h5' gutterBottom>{!loading && user ? user.loyaltyClaimCount ?? 0 : <CircularProgress size={25} />}</Typography>
        <Typography variant='body1' gutterBottom sx={{ textTransform: 'uppercase' }}>Ecover<br/>Refills</Typography>
      </Box>
      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Box component='img' src={PlasticSaved} sx={{ mb: 2 }} />
        <Typography variant='h5' gutterBottom>{!loading && user ? `${getUserReduction(user, EnvironmentalImpactMaterial.Plastic)}` : <CircularProgress size={25} />}</Typography>
        <Typography variant='body1' gutterBottom sx={{ textTransform: 'uppercase' }}>Plastic<br/>Saved</Typography>
      </Box>
    </Box>

    <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item xs={12} sm={6} md={4} sx={{ borderRight: { xs: 'none', sm: '1px solid black' }, px: 2 }}>
        <Typography variant='h6'>Settings</Typography>
        <List>
          <ListItem button component={RouterLink} to={ROUTE_ACCOUNT_DETAILS}>
            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
            <ListItemText primary='Personal Details' />
          </ListItem>
          <ListItem button component={RouterLink} to={ROUTE_ACCOUNT_LOCATION_SERVICES}>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <ListItemText primary='Location Services' />
          </ListItem>
          <ListItem button component={RouterLink} to={ROUTE_ACCOUNT_PASSWORD}>
            <ListItemIcon><PasswordIcon /></ListItemIcon>
            <ListItemText primary='Password' />
          </ListItem>
          <ListItem button onClick={signOut}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary='Sign Out' />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ borderRight: { xs: 'none', md: '1px solid black' }, px: 2 }}>
        <Typography variant='h6'>Help</Typography>
        <List>
          <HowItWorks closeButtonLabel='Close'>
            <ListItem button>
              <ListItemIcon><HelpIcon /></ListItemIcon>
              <ListItemText primary='How It Works' />
            </ListItem>
          </HowItWorks>
          <ListItem button component={RouterLink} to={ROUTE_FAQS}>
            <ListItemIcon><FAQIcon /></ListItemIcon>
            <ListItemText primary='FAQs' />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={4} sx={{ px: 2 }}>
        <Typography variant='h6'>Terms & Privacy</Typography>
        <List>
          <ListItem button component={RouterLink} to={ROUTE_TERMS}>
            <ListItemIcon><TermsIcon /></ListItemIcon>
            <ListItemText primary='Terms' />
          </ListItem>
          <ListItem button component={Link} href={LINK_PRIVACY_POLICY}>
            <ListItemIcon><PrivacyTipIcon /></ListItemIcon>
            <ListItemText primary='Privacy' />
          </ListItem>
          <ListItem button component={Link} onClick={handleIub}>
            <ListItemIcon><CookieIcon /></ListItemIcon>
            <ListItemText primary='Cookie Preferences' />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  </>
}

export default Account
