import { Box, Container, Link } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'

import { ROUTE_LANDING } from '~/routes/Routes'

import RefillLogo from '~/assets/images/refill-logo.svg'
import EcoverLogo from '~/assets/images/logo.svg'

const AuthPage: React.FC = () => {
  return <Container component="main" maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '300px' }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, zIndex: 2 }}>
      <Link component={RouterLink} to={ROUTE_LANDING}>
        <Box
          component="img"
          sx={{
            maxWidth: '100%',
            height: { xs: 60, sm: 75 },
          }}
          alt="Ecover"
          src={EcoverLogo}
        />
      </Link>
      <Box
        component="img"
        sx={{
          maxWidth: '100%',
          height: { xs: 50, sm: 75 },
        }}
        alt="Ecover Refill Rewards"
        src={RefillLogo}
      />
    </Box>
    <Outlet />
  </Container>
}

export default AuthPage
