import { Auth } from 'aws-amplify'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { promotion } from '~/config/promotion'
import PromotionalUserService from '~/services/PromotionalUserService'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'

export const checkIfAuthenticated = createAsyncThunk(
  'auth/checkIfAuthenticated',
  async () => {
    const user = await Auth.currentAuthenticatedUser()

    return user.attributes
  },
)

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string, password: string }) => {
    const user = await Auth.signIn(email, password)

    return user.attributes
  },
)

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (user: Omit<PromotionalUser, 'userId'> & { password: string }): Promise<void> => {
    const { email, password, ...rest } = user

    const cognitoResponse = await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: user.firstName,
        'custom:campaignId': promotion.id,
      },
    })

    const cognitoId = cognitoResponse.userSub

    await PromotionalUserService.createPromotionalUser(promotion.id, {
      email,
      locale: promotion.locale,
      ...rest,
      cognitoId,
    })
  },
)

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUp',
  async ({ username, code }: { username: string, code: string }) => {
    await Auth.confirmSignUp(username, code.replace(/\s/g, ''))
  },
)

export const resendSignUpCode = createAsyncThunk(
  'auth/resendSignUpCode',
  async (username: string) => {
    await Auth.resendSignUp(username)
  },
)

export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    await Auth.signOut()
  },
)

export const getPromotionalUser = createAsyncThunk(
  'auth/getPromotionalUser',
  async ({ userId, signal }: { userId: string, signal?: AbortSignal }): Promise<PromotionalUser> => {
    return await PromotionalUserService.getPromotionalUser(promotion.id, userId, { signal })
  },
)

export const getPromotionalUserMeta = createAsyncThunk(
  'auth/getPromotionalUserMeta',
  async ({ userId, signal }: { userId: string, signal?: AbortSignal }): Promise<PromotionalUser> => {
    return await PromotionalUserService.getPromotionalUserMeta(promotion.id, userId, { signal })
  },
)
