import { EnvironmentalImpactMaterial } from '~/types/common/EnvironmentalImpact'
import PromotionalUser from '~/types/promotionalUser/PromotionalUser'

export const getUserReduction = (user: PromotionalUser | null, material: EnvironmentalImpactMaterial): string => {
  let value = 0

  if (user?.environmentalImpact?.reduction?.[material]) {
    value = user.environmentalImpact.reduction[material] * 1000
  }

  if (value === 0) {
    return '0g'
  }

  if (value > 1000) {
    return `${(value / 1000).toFixed(2)}kg`
  }

  return `${value.toFixed(2)}g`
}
