
import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import FlowerLoader from '~/components/Loader/FlowerLoader'
import { Page } from '~/components/Page/Page'
import { getPromotionalUser, getPromotionalUserMeta } from '~/redux/features/auth/actions'
import { RootState, useAppDispatch } from '~/redux/store'

interface AuthenticatedRouteGuardProps {
  isAuthenticating: boolean
  isAuthenticated: boolean
}

const AuthenticatedRouteGuard: React.FC<AuthenticatedRouteGuardProps> = ({ isAuthenticating, isAuthenticated }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const authUser = useSelector((state: RootState) => state.auth.user)
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) abort.current.abort()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && authUser?.userId) {
      void dispatch(getPromotionalUserMeta({ userId: authUser.userId, signal: abort.current.signal }))
    }
  }, [isAuthenticated])

  if (isAuthenticating) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <FlowerLoader sx={{ width: '100px', height: '100px' }} />
    </Box>
  }

  return isAuthenticated
    ? <Page route={location.pathname}><Outlet /></Page>
    : <Navigate to="/login" replace state={{ from: location }} />
}

export default AuthenticatedRouteGuard
