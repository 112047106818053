import { useEffect, useState } from 'react'
import { FormikValues } from 'formik'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { Box, Link, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { RootState, useAppDispatch } from '~/redux/store'
import { signUp } from '~/redux/features/auth/actions'
import { storeSignUp } from '~/redux/features/auth/authSlice'

import EmailAndPassword from './Steps/EmailAndPassword'
import PersonalInformation from './Steps/PersonalInformation'

import { ROUTE_CONFIRM_SIGN_UP, ROUTE_LOGIN } from '~/routes/Routes'
import { useSelector } from 'react-redux'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

import { AWSException } from '~/types/errors/AWSException'
import { isNotifiable } from '~/types/guards/errors'
import Bugsnag from '@bugsnag/js'
import Agreements from './Steps/Agreements'

enum STEP {
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  AGREEMENTS = 'AGREEMENTS',
}

const STEP_ORDER = [
  STEP.EMAIL_AND_PASSWORD,
  STEP.PERSONAL_INFORMATION,
  STEP.AGREEMENTS,
]

const SignUpView: React.FC = (): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(STEP.EMAIL_AND_PASSWORD)
  const [isLoading, setIsLoading] = useState(false)
  const details = useSelector((state: RootState) => state.auth.signUp)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  const handleSignUp = async (values?: FormikValues): Promise<void> => {
    setIsLoading(true)

    try {
      const data = values ? { ...details, ...values } : details

      await dispatch(signUp(data)).unwrap()

      navigate(ROUTE_CONFIRM_SIGN_UP)
    } catch (error) {
      let message = 'Sorry, there was a problem signing up'
      if ((error as AWSException).name === 'UsernameExistsException') {
        message = 'An account already exists'
      } else if (isNotifiable(error)) {
        Bugsnag.notify(error)
      }

      dispatch(addNotification({
        type: 'error',
        message,
      }))
    } finally {
      setIsLoading(false)
    }
  }

  const handleNextStep = async (values?: FormikValues): Promise<void> => {
    const currentIndex = STEP_ORDER.indexOf(currentStep)
    const nextIndex = currentIndex + 1

    dispatch(storeSignUp(values))

    if (nextIndex >= STEP_ORDER.length) {
      return await handleSignUp(values)
    }

    setCurrentStep(STEP_ORDER[nextIndex])
  }

  const renderStep = (): JSX.Element => {
    switch (currentStep) {
      case STEP.EMAIL_AND_PASSWORD:
        return <EmailAndPassword onNextStep={handleNextStep} />
      case STEP.PERSONAL_INFORMATION:
        return <PersonalInformation onNextStep={handleNextStep} />
      case STEP.AGREEMENTS:
        return <Agreements onNextStep={handleNextStep} isLoading={isLoading} />
    }
  }

  return <>
    <Helmet>
      <title>Sign Up & Join The Refillution! | Ecover Loyalty Rewards</title>
    </Helmet>
    {renderStep()}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
      <Typography>Already have an account?</Typography>
      <Link component={RouterLink} to={ROUTE_LOGIN} variant="button">
          Sign in
      </Link>
    </Box>
  </>
}

export default SignUpView
