import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { LINK_PRIVACY_POLICY } from '~/constants/externalLinks'
import { ROUTE_FAQS, ROUTE_LANDING, ROUTE_LOGIN, ROUTE_SIGN_UP, ROUTE_TERMS } from '~/routes/Routes'

import YellowDrop from '~/assets/images/yellow-drop.svg'
import RefillutionBadge from '~/assets/images/refillution-badge.svg'
import RefillLogo from '~/assets/images/refill-logo.svg'
import EcoverLogo from '~/assets/images/logo.svg'
import HowItWorks from '~/components/HowItWorks/HowItWorks'

import BottleBeingRefilledImage from '~/assets/images/bottle-being-refilled@2x.png'

const LandingView: React.FC = () => {
  return <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Helmet>
      <title>Join The Refillution! | Ecover Loyalty Rewards</title>
    </Helmet>
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid item xs={0} lg={5} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Box component="img" src={BottleBeingRefilledImage} sx={{ position: 'relative', top: -50, height: '900px', left: -500, zIndex: 3 }} />
      </Grid>
      <Grid item xs={12} lg={7} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'center' }}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', pt: 2, zIndex: 2 }}>
            <Link component={RouterLink} to={ROUTE_LANDING}>
              <Box
                component="img"
                sx={{
                  maxWidth: '100%',
                  height: { xs: 60, sm: 75 },
                }}
                alt="Ecover"
                src={EcoverLogo}
              />
            </Link>
            <Box
              component="img"
              sx={{
                maxWidth: '100%',
                height: { xs: 50, sm: 75 },
              }}
              alt="Ecover Refill Rewards"
              src={RefillLogo}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, py: 4, justifyContent: { xs: 'center', lg: 'flex-start' } }}>
            <Box sx={{ my: 2, width: '100%', position: 'relative' }}>
              <Box
                component="img"
                src={YellowDrop}
                alt="Yellow Drop"
                sx={{ position: 'absolute', top: 25, left: '-75px', maxWidth: '175px', height: 'auto' }}
              />
              <Box
                component="img"
                src={RefillutionBadge}
                alt="Refillution Badge"
                sx={{ position: 'absolute', top: 25, right: { xs: '-100px', sm: '-75px' }, maxWidth: '200px', height: 'auto' }}
              />
              <Typography variant="h2" sx={{ fontSize: { xs: 35, sm: 40, md: 50 }, position: 'relative', zIndex: 2 }}>
                REUSE<br/> YOUR<br/> BOTTLE.<br/>
                FILL UP ON<br/> REWARDS.
              </Typography>
            </Box>
            <Box sx={{ my: 2, width: '100%' }}>
              <Typography fontWeight={600} sx={{ fontSize: { xs: 20, md: 25 } }}>
                Refill your bottle, save<br/> plastic and earn money<br/> on every refill.
              </Typography>
            </Box>
            <Box sx={{ borderTop: 1, mt: 2, pt: 2, width: '100%' }}>
              <HowItWorks closeButtonLabel="GET STARTED">
                <Button variant="text" sx={{ fontSize: 14 }}>How it works</Button>
              </HowItWorks>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2, mt: { xs: 2, md: 4 }, width: '100%' }}>
              <Button component={RouterLink} to={ROUTE_SIGN_UP} variant="contained" sx={{ flexGrow: 1, mr: 2 }}>Sign Up</Button>
              <Button component={RouterLink} to={ROUTE_LOGIN} variant="outlined" sx={{ flexGrow: 1 }}>Sign In</Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 4, py: 2, mt: 'auto' }}>
              <Link component={RouterLink} to={ROUTE_FAQS}>FAQs</Link>
              <Link component={RouterLink} to={ROUTE_TERMS}>Terms</Link>
              <Link href={LINK_PRIVACY_POLICY} target="_blank">Privacy</Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Container>
}

export default LandingView
