import { Box, Link, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { atHomeTable, inStoreTable } from './TermsProductTableConfigs'
import { TermsTable } from './TermsTable'

const TermsView: React.FC = () => {
  return <>
    <Helmet>
      <title>Terms & Conditions | Ecover Loyalty Rewards</title>
    </Helmet>
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">
          <strong>CLOSE DATE FOR PURCHASES 31st July 2023</strong>
        </Typography>
        <Typography variant="body1">
          <strong>CLOSE DATE FOR CLAIMS 29th October 2023</strong>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          This is a reward scheme, offered at the sole discretion and exclusively by Ecover (UK) Limited (&ldquo;<b>Ecover</b>&rdquo;), that allows registered account holders who purchase any of the Promotional Products set out in paragraph 6 to claim a partial refund against the purchase price of Qualifying Purchases (&ldquo;<b>Reward Scheme</b>&rdquo;). The Reward Scheme is governed by these Terms and Conditions.
        </Typography>
      </Box>
      <ol>
        <li>
          <Typography variant="body1">
            This Reward Scheme is only open to legal residents of the United Kingdom aged 18 or over, excluding employees of Ecover, their families, agents, or anyone else professionally connected with this Reward Scheme. Neither retailers nor wholesalers may apply. Ecover reserves the right to verify the eligibility of registered account holders. Ecover may require such information as it considers reasonably necessary for this purpose and a Claim may be withheld unless and until Ecover is satisfied with the verification.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Purchase Period: for the purchase of a Promotional Product (listed in paragraph 6) to qualify for the Reward Scheme it must be purchased between 00.01 GMT on 21st March 2022 and 23.59 GMT on the 31st July 2023 (&ldquo;<b>Qualifying Purchase(s)</b>&rdquo;).
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            On making a Qualifying Purchase and meeting these Terms and Conditions the purchaser will be entitled to claim a monetary value reward (&ldquo;<b>Reward</b>&rdquo;)
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            &ldquo;<strong>Claim Period</strong>&rdquo;: the registered account holder must claim the Reward from a Qualifying Purchase between 00.01 (UK time) on 21st March 2022 and 23.59 (UK time) on 29th October 2023 (&ldquo;Closing Date&rdquo;).
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ fontWeight: 600 }} gutterBottom>
            How to redeem the points:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }} gutterBottom>
            Setting up an account
          </Typography>
          <Typography variant="body1" gutterBottom>
            To register for the Reward Scheme, visit <Link href="https://www.ecoverrefillrewards.com" style={{ color: '#000' }}>www.ecoverrefillrewards.com</Link> (&ldquo;<b>Website</b>&rdquo;) and complete the application form in full, providing:
          </Typography>
          <ul style={{ listStyle: 'disc', marginTop: 10, marginBottom: 10 }}>
            <li>
              <Typography variant="body1">
                Your name, address, email and mobile phone number.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Only one registered account per person, and only one registered account per household.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }} gutterBottom>
            Earning Rewards and validation
          </Typography>
          <Typography variant="body1" gutterBottom>
            Registered account holders can then add Qualifying Purchases to their account, which once validated will earn Rewards, which in turn entitle them to claim a partial refund of the original purchase price, credited to their bank account. The value of the refund is determined by the number and type of Promotional Products purchased.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Rewards are earned on Qualifying Purchases:
          </Typography>

          <ul style={{ listStyle: 'disc', marginBottom: 10 }}>
            <li>
            <Typography variant="body1">
              An instore refill of the Ecover Dilute Laundry Liquid 1.5L Pack Size will have a Reward value of £1.00
            </Typography>
            </li>
            <li>
            <Typography variant="body1">
              An at home refill of Ecover All Purpose Cleaner 5L Pack Size will have a Reward value of £2.30
            </Typography>
            </li>
          </ul>

          <Typography variant="body1" gutterBottom>
            If the value of the Reward is changed during the Claim Period, the value of the refund will be calculated at the date a refund is requested, not the original purchase date. Ecover reserves the right to change the value of Rewards. Updates to these Terms and Conditions, including the value of any Rewards, will be published on the Website from time-to-time during the Claim Period.
          </Typography>
          <Typography variant="body1" gutterBottom>
            In order to submit a Qualifying Purchase for validation, registered account holders must:
          </Typography>
          <ol type="i">
            <li>
              <Typography variant="body1">
                <strong>For refill products Claimed at home:</strong> Claims must be submitted within 90 days of purchase using the AT HOME claim form. The purchase should be submitted for validation, via the registered account holder's account on the Website, with the following information:
              </Typography>
              <ul style={{ listStyle: 'disc', marginTop: 10, marginBottom: 10 }}>
                <li>
                  <Typography variant="body1">
                    Date of Purchase
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Place of Purchase
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Product Size
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Product
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Proof of Purchase - a till receipt or invoice showing the date and place of purchase, along with the product purchased. This can be evidenced by uploading an image to the Website via the registered account holder's account.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    A photograph of the front of the product, with the full product in view clearly showing the qualifying product purchased and the volume. This can be evidenced by uploading an image to the Website via the registered account holder's account.
                  </Typography>
                </li>
                </ul>
              </li>
              <li>
              <Typography variant="body1">
                <strong>For refills made at a refill station in participating stores,</strong> the correct Ecover container for the corresponding refill must be used the submission must be made at the time of purchase by using the INSTORE claim form, in the participating store . Location services must be enabled on the mobile phone used for the submission, in order to verify the location. The following information will be required:
              </Typography>
              <ul style={{ listStyle: 'disc', marginTop: 10, marginBottom: 10 }}>
                <li>
                  <Typography variant="body1">
                    Product Size
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">Product</Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    A photograph of the front of the product, with the full product in view clearly showing the qualifying product purchased and the volume. This can be evidenced by uploading an image to the Website via your account.
                  </Typography>
                </li>
              </ul>
            </li>
          </ol>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600 }}
            gutterBottom
          >
            Claiming a refund
          </Typography>
          <Typography variant="body1">
            To claim a refund of the value of Rewards collected from Qualifying Purchases that have been accumulated by a registered account holder (&ldquo;<b>Claim</b>&rdquo;), a Claim must be submitted via the registered account holder's account on the Website and the following information will be required;
          </Typography>

          <ul style={{ listStyle: 'disc', marginTop: 20, marginBottom: 20 }}>
            <li>
              <Typography variant="body1">
                UK bank account details for payment of the refund, including the Account Name, Sort Code and Account Number.
              </Typography>
            </li>
          </ul>

          <Typography variant="body1" gutterBottom>
            The following conditions apply to making a Claim:
          </Typography>

          <ol type="i" style={{ marginBottom: 20 }}>
            <li>
              <Typography variant="body1">
                The Claim must be received within the Claim Period. Any Rewards collected that are not redeemed by the end of the Claim Period will not be valid for payment.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Registered account holders must hold a valid UK bank account in order to receive payment. No alternative payment is available.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Subject to the maximum refund amount, there is no limit on the number of Claims a registered account holder can make.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                The maximum cumulative refund per registered account holder is £50.
              </Typography>
            </li>
          </ol>

          <Typography variant="body1" gutterBottom>
            Please retain receipt as it will be required to validate a Claim.
          </Typography>
          <Typography variant="body1" gutterBottom>
            If a Claim is valid the registered account holder will receive a bank payment for the cumulative total value of Rewards collected (up to a value of £50 from Qualifying Purchases). This will be transferred to the bank account details provided in the Claim. Please allow 30 days for receipt from approval of a valid claim.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            The Promotional Products included in this Reward Scheme are:
          </Typography>

          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
              IN STORE PACK SIZES
            </Typography>
            <TermsTable tableConfig={inStoreTable}/>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
              BULK PACKS & AT HOME
            </Typography>
            <TermsTable tableConfig={atHomeTable}/>
          </Box>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Invalid Claims: any Claims received after the Closing Date will not be accepted and will not be considered a valid Claim. Late, incomplete or corrupted entries or those not made in accordance with the Terms and Conditions will be automatically disqualified. No responsibility will be accepted by Ecover for entries delayed for any reason whatsoever, ineligible or incomplete entries. Ecover may, in its sole discretion, invalidate Claims deemed to be in breach of these Terms and Conditions, contrary to the spirit of the Reward Scheme or otherwise bringing the Reward Scheme into disrepute.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            By participating in the Reward Scheme, registered account holders agree to be bound by these Terms and Conditions and by the decisions of Ecover, which are final in all matters relating to the Reward Scheme. No correspondence will be entered into.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Claims are non-transferable and cannot be exchanged for any alternatives in whole or in part.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Ecover reserves the right to vary these Terms and Conditions or to terminate or cancel the Reward Scheme and/or a reward at any time without notice in circumstances which are deemed to be outside of its reasonable control or otherwise where fraud, abuse, and/or an error (human or computer) affects or could affect the proper operation of this Reward Scheme or the awarding of refunds. For instance, if, for any reason, any aspect of this Reward Scheme is not capable of running as planned, including, but not limited to, by reason of infection by computer virus, network failure, bugs, tampering, unauthorised intervention, fraud, technical failures or any cause beyond the control of Ecover which corrupts or affects the administration, security, fairness, integrity or proper conduct of this Reward Scheme, Ecover may in its sole discretion terminate, modify or suspend the Reward Scheme, or invalidate any affected Claims.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Bulk Claims made from retailers, consumer groups or third parties will not be accepted. Incomplete or illegible Claims, by or via third parties or syndicates, Claims by macros or other automated means (including systems which can be programmed to enter), and Claims which do not satisfy the requirements of these Terms and Conditions in full will be invalidated. If it becomes apparent that a registered account holder is using a computer(s) to circumvent this condition by, for example, the use of 'script', 'brute force', masking their identity by manipulating IP addresses, using identities other than their own or any other automated means in order to increase their number of Claims in a way that in the opinion of Ecover is not consistent with the spirit of the Reward Scheme, that person's Claims will be invalidated and any Claim award will be void.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Nothing in these Terms and Conditions shall limit or exclude the liability of any person or death caused by their negligence or for fraud. Subject to that, in entering into this Reward Scheme, registered account holders do so at their sole risk. Ecover and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained in connection with this Reward Scheme. This Reward Scheme and these Terms and Conditions do not affect the statutory rights of any person.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            How to opt-out of the Reward Scheme: a registered account holder can opt-out of the Reward Scheme and deactivate their account by sending an email to help@ecoverrefillrewards.com. All points accumulated by the registered account holder will be forfeited unless such points are part of a valid Claim awaiting bank transfer to the registered account holder.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            By entering the Reward Scheme, all registered account holders consent to the transfer of their personal data to Ecover and its agencies, and the holding and processing of such data by Ecover and its agencies, and for the purposes of the administration of this Reward Scheme. If you do not wish your personal data to be used for this purpose, you should not enter the Reward Scheme.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Aside from the use of entry data for the performance of this Reward Scheme, Ecover will not use personal data for any other purpose without the express consent of the registered account holder. Any personal data will be processed in accordance with Ecover's Privacy Policy. See <Link href="https://www.ecover.com/privacy-policy/">https://www.ecover.com/privacy-policy/</Link> for our full privacy policy.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Any website, including the Website, where these Terms and Conditions are posted is only intended to be accessed from the United Kingdom and where permitted by law. Ecover makes no representation that materials relating to the Reward Scheme are appropriate or available for use at other locations. Access to them from territories where their contents are illegal is strictly prohibited.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            These Terms and Conditions shall be governed by the laws of England and Wales and any dispute will be subject to the exclusive jurisdiction of the English courts.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            If a court or any other competent authority finds that any of the Terms and Conditions (or any part of any term) is invalid, illegal or unenforceable, that term or part of that term shall, to the extent required, be deemed deleted, and the validity and enforceability of the other Terms and Conditions shall not be affected. Ecover's failure to enforce any of the Terms and Conditions will not constitute a waiver of that provision.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Entry to the Reward Scheme is deemed acceptance of these Terms and Conditions.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Contact details: Ecover (UK) Limited, 1 Eton Street, Richmond, Surrey TW9 1AG. Email: <Link href="mailto:help@ecoverrefillrewards.com">help@ecoverrefillrewards.com</Link> telephone: <Link href="tel:0204 553 0398">0204 553 0398</Link> (Standard geographic number, normal phone provider call rates apply) Monday to Friday 9am - 5pm
          </Typography>
        </li>
      </ol>
    </Box>
  </>
}

export default TermsView
