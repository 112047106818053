const InStore: React.FC = () => {
  return <ol>
    <li>Refill your Ecover bottle</li>
    <li>Scan the QR code in store</li>
    <li>Take a photo of your bottle</li>
    <li>Confirm product details</li>
    <li>Earn money</li>
  </ol>
}

export default InStore
