import { Auth } from 'aws-amplify'

import { signOut } from '~/redux/features/auth/actions'
import store from '~/redux/store'

class Service {
  protected uri: string

  constructor (uri: string) {
    this.uri = uri
  }

  protected getPath (path: string): string {
    if (path.startsWith('/')) path = path.substring(1, path.length)

    return `${this.uri}${path}`
  }

  protected async request (input: RequestInfo, init: RequestInit = {}): Promise<Response> {
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
    const defaultHeaders = { Authorization: `Bearer ${jwt}` }

    input = typeof input === 'string' ? this.getPath(input) : input
    init.headers = init.headers ? { ...defaultHeaders, ...init.headers } : defaultHeaders

    const response = await fetch(input, init)

    if (response.status === 401) {
      await store.dispatch(signOut()).unwrap()
    }

    return response
  }

  protected buildQueryString (params: Record<string, string | number>): string {
    return `?${Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
}

export default Service
