import SubmissionProcessorService from './SubmissionProcessorService'

import PromotionalUser from '~/types/promotionalUser/PromotionalUser'
import { CreatePromotionalUserPayload } from '~/types/promotionalUser/service'
import { PromotionalUserUpdatePayload } from '~/types/promotionalUser/request/PromotionalUserUpdatePayload'
import { PromotionalUserClaimsResponse } from '~/types/claim/response/PromotionalUserClaimsResponse'

class PromotionalUserService extends SubmissionProcessorService {
  public async createPromotionalUser (promotionId: string, payload: CreatePromotionalUserPayload): Promise<boolean> {
    const response = await fetch(this.getPath(`promotion/${promotionId}/user`), {
      method: 'POST',
      body: JSON.stringify({
        cognitoId: payload.cognitoId,
        locale: payload.locale,
        email: payload.email,
        company: payload.company,
        title: payload.title,
        firstName: payload.firstName,
        lastName: payload.lastName,
        addressLineOne: payload.addressLineOne,
        addressLineTwo: payload.addressLineTwo,
        city: payload.city,
        county: payload.county,
        postalCode: payload.postalCode,
        mobileNo: payload.mobileNo,
        consumerType: payload.consumerType,
        marketing: payload.marketing,
      }),
    })

    return response.status === 204 ? true : await Promise.reject(response)
  }

  public async getPromotionalUser (promotionId: string, userId: string, init?: { signal?: AbortSignal }): Promise<PromotionalUser> {
    const response = await this.request(`promotion/${promotionId}/user/${userId}`, init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getPromotionalUserMeta (promotionId: string, userId: string, init?: { signal?: AbortSignal }): Promise<PromotionalUser> {
    const response = await this.request(`promotion/${promotionId}/user/${userId}/meta`, init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async updatePromotionalUser (promotionId: string, userId: string, payload: PromotionalUserUpdatePayload): Promise<boolean> {
    const response = await this.request(`promotion/${promotionId}/user/${userId}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })

    return response.status === 204 ? true : await Promise.reject(response)
  }

  public async getClaimsForPromotionalUser (promotionId: string, userId: string, nextToken: string | null, init?: { query?: {[key: string]: string}, signal: AbortSignal }): Promise<PromotionalUserClaimsResponse> {
    const queryString = this.buildQueryString({ ...(init?.query ? init.query : {}), ...(nextToken ? { nextToken } : {}) })
    const response = await this.request(`promotion/${promotionId}/user/${userId}/claims${queryString}`, init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }
}

export default new PromotionalUserService()
