import { SvgIcon, SvgIconProps } from '@mui/material'

const CashPayoutIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props}>
    <path d="M23.59,13.75,22.05,12.2a1.39,1.39,0,0,0-1.95,0l-2.41-1.84a6,6,0,0,0-1.7-.9,5.92,5.92,0,0,0-1.91-.31,6,6,0,0,0-1.27.13,6.48,6.48,0,0,0-1.22.41,2.74,2.74,0,0,1-1.13.25H7.9a1.9,1.9,0,0,0-1.13.36l-.92-.71L4.66,8.33,3.6,7A2.12,2.12,0,0,0,3,6.54a2.13,2.13,0,0,0-.74-.23,2,2,0,0,0-2,3.06l3,5.06a7.38,7.38,0,0,0,.83,1.1,6.85,6.85,0,0,0,1,.9A6.54,6.54,0,0,0,9,17.62h5.71l-.06.07a1.39,1.39,0,0,0,0,2l1.55,1.55a1.37,1.37,0,0,0,1,.4,1.38,1.38,0,0,0,1-.4l5.48-5.49A1.37,1.37,0,0,0,23.59,13.75ZM9,16.37a5.32,5.32,0,0,1-3.06-1,5.23,5.23,0,0,1-.83-.73,5.32,5.32,0,0,1-.67-.89l-3-5.06a.74.74,0,0,1,0-.81.77.77,0,0,1,.73-.35.75.75,0,0,1,.29.09.79.79,0,0,1,.22.19L3.7,9.15,5,10.52l1,.78a1.72,1.72,0,0,0-.1.6,2,2,0,0,0,2,2h4.9a.63.63,0,1,0,0-1.26H7.9a.72.72,0,0,1-.57-1.14l.07-.08a.71.71,0,0,1,.5-.21h2.56a4,4,0,0,0,1.66-.36,4.51,4.51,0,0,1,1-.32,4.76,4.76,0,0,1,2.51.14,4.67,4.67,0,0,1,1.34.71l2.27,1.73-3.28,3.29Z"/>
    <path d="M8.54,8.43a3,3,0,1,0-3-3A3,3,0,0,0,8.54,8.43Zm-.82-2a.52.52,0,0,1-.51-.9.48.48,0,0,1,.24-.06l.88,0a.05.05,0,0,1,.05,0,0,0,0,0,1,0,0l-.47.73A.52.52,0,0,1,7.72,6.42Zm.83.68h0A.51.51,0,0,1,8,6.59a.4.4,0,0,1,.07-.24l.4-.79a.05.05,0,0,1,.07,0l0,0,.4.77a.47.47,0,0,1,.08.26A.51.51,0,0,1,8.55,7.1Zm1.51-.88h0a.51.51,0,0,1-.7.19.52.52,0,0,1-.2-.19l-.47-.73a0,0,0,0,1,0,0,.05.05,0,0,1,.05,0l.89,0a.53.53,0,0,1,.24.06h0A.52.52,0,0,1,10.06,6.22ZM9.35,4.28h0a.51.51,0,0,1,.7.19h0a.51.51,0,0,1-.19.7.48.48,0,0,1-.26.07l-.87,0s0,0,0,0a.06.06,0,0,1,0-.06l.47-.74A.52.52,0,0,1,9.35,4.28Zm-.81-.69a.48.48,0,0,1,.26.07A.53.53,0,0,1,9,4.37l-.4.76s0,0,0,0a.07.07,0,0,1,0,0l-.4-.78A.42.42,0,0,1,8,4.11.52.52,0,0,1,8.54,3.59ZM7,4.48H7a.51.51,0,0,1,.7-.2.66.66,0,0,1,.19.18l.47.74a0,0,0,0,1,0,.06s0,0,0,0l-.86,0A.52.52,0,0,1,7,4.48Z"/>
  </SvgIcon>
}

export default CashPayoutIcon
