import { lazy, Suspense, useEffect } from 'react'
import {
  Route,
  Routes,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { checkIfAuthenticated } from '~/redux/features/auth/actions'
import { RootState, useAppDispatch } from '~/redux/store'

import AuthRoutes from './AuthRoutes'
import AuthenticatedRouteGuard from './AuthenticatedRouteGuard'
import AuthRouteGuard from './AuthRouteGuard'
import PublicRoutes from './PublicRoutes'
import AppRouteLoader from './AppRouteLoader'
import { lazyWithPreload } from './lazyWithPreload'

import OnRouteChange from '~/routes/OnRouteChange'
import HomeView from '~/views/Home/HomeView'
import MyRefillsView from '~/views/MyRefills/MyRefillsView'
import MyRewardsView from '~/views/MyRewards/MyRewardsView'
import AccountView from '~/views/Account/AccountView'
import PersonalDetailsView from '~/views/Account/PersonalDetails/PersonalDetailsView'
import PasswordView from '~/views/Account/Password/PasswordView'
import LocationServicesView from '~/views/Account/LocationServices/LocationServicesView'
import NotFound from '~/views/Error/NotFound'

import { ROUTE_ACCOUNT, ROUTE_ACCOUNT_DETAILS, ROUTE_ACCOUNT_PASSWORD, ROUTE_HOME, ROUTE_ACCOUNT_LOCATION_SERVICES, ROUTE_MY_REFILLS, ROUTE_MY_REWARDS, ROUTE_REFILL, ROUTE_REWARDS, ROUTE_CLAIM_INFORMATION_REQUEST } from './Routes'

const LazyRefillView = lazyWithPreload(async () => await import('~/views/Refill/RefillView'))
const LazyRewardsView = lazyWithPreload(async () => await import('~/views/Rewards/RewardsView'))
const LazyClaimInformationRequestView = lazy(async () => await import('~/views/ClaimInformationRequest/ClaimInformationRequestView'))

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const isAuthenticating = useSelector((state: RootState) => state.auth.isAuthenticating)

  useEffect(() => {
    void dispatch(checkIfAuthenticated())
    void preloadViews()
  }, [])

  const preloadViews = async (): Promise<void> => {
    await LazyRefillView.preload()
    void LazyRewardsView.preload()
  }

  return (
    <Suspense fallback={<AppRouteLoader />}>
      <OnRouteChange />
      <Routes>
        <Route element={<AuthenticatedRouteGuard isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated} />}>
          <Route path={ROUTE_HOME} element={<HomeView />} />
          <Route path={ROUTE_REFILL} element={<LazyRefillView />} />
          <Route path={ROUTE_REWARDS} element={<LazyRewardsView />} />
          <Route path={ROUTE_MY_REFILLS} element={<MyRefillsView />} />
          <Route path={ROUTE_MY_REWARDS} element={<MyRewardsView />} />
          <Route path={ROUTE_ACCOUNT} element={<AccountView />} />
          <Route path={ROUTE_ACCOUNT_DETAILS} element={<PersonalDetailsView />} />
          <Route path={ROUTE_ACCOUNT_PASSWORD} element={<PasswordView />} />
          <Route path={ROUTE_ACCOUNT_LOCATION_SERVICES} element={<LocationServicesView />} />
          <Route path={ROUTE_CLAIM_INFORMATION_REQUEST} element={<LazyClaimInformationRequestView />} />
        </Route>

        <Route element={<AuthRouteGuard isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated} />}>
          {AuthRoutes}
        </Route>

        {PublicRoutes}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
