import { SvgIcon, SvgIconProps } from '@mui/material'

const FlowerLoader: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon className="flower-loader" viewBox='0 0 149 161' {...props}>
    <path className="flower-loader-petal flower-loader-petal-sixth" d="M6.64344 42.6177C13.2868 31.2529 26.5734 27.4647 37.9619 34.0941C41.7581 35.9882 43.6562 38.8294 46.5033 41.6706L68.3313 74.8177C69.2804 75.7647 69.2804 76.7118 68.3313 77.6588C67.3823 78.6059 66.4333 78.6059 65.4842 78.6059L26.5734 76.7118C22.7772 76.7118 18.981 75.7647 15.1848 73.8706C3.79631 67.2412 0.000131088 53.9824 6.64344 42.6177Z" fill="#0053B4"/>
    <path className="flower-loader-petal flower-loader-petal-fifth" d="M6.64344 120.277C0.000131766 108.912 3.79631 95.6533 15.1848 89.0239C18.981 87.1297 22.7772 86.1827 26.5734 86.1827L66.4332 84.2886C67.3823 84.2886 68.3313 84.2886 68.3313 85.2356C69.2804 86.1827 68.3313 87.1298 68.3313 88.0768L47.4524 120.277C45.5543 124.065 42.7071 126.906 38.911 128.8C27.5224 135.43 13.2868 131.642 6.64344 120.277Z" fill="#0053B4"/>
    <path className="flower-loader-petal flower-loader-petal-forth" d="M74.9748 160.053C62.6372 160.053 52.1978 149.635 52.1978 137.323C52.1978 133.535 53.1468 129.747 55.0449 125.959L73.0767 90.9176C73.0767 89.9705 74.0258 89.0234 74.9748 89.0234C75.9239 89.0234 76.8729 89.9705 76.8729 90.9176L94.9048 125.012C96.8029 128.8 97.7519 132.588 97.7519 136.376C97.7519 149.635 87.3124 160.053 74.9748 160.053Z" fill="#0053B4"/>
    <path className="flower-loader-petal flower-loader-petal-third" d="M143.306 120.277C149.949 108.912 146.153 94.7062 134.764 89.0239C130.968 87.1297 127.172 86.1827 123.376 86.1827L84.4649 84.2886C83.5159 84.2886 82.5668 84.2886 82.5668 85.2356C81.6178 86.1827 82.5668 87.1298 82.5668 88.0768L103.446 120.277C105.344 124.065 108.191 126.906 111.987 128.8C122.427 135.43 136.662 131.642 143.306 120.277Z" fill="#0053B4"/>
    <path className="flower-loader-petal flower-loader-petal-second" d="M143.306 41.6709C136.663 30.3062 122.427 26.5179 111.988 33.1473C108.191 35.0415 106.293 37.8826 104.395 40.7238L81.6182 74.8179C81.6182 75.765 81.6182 76.7121 81.6182 77.6591C82.5672 78.6062 82.5672 78.6062 83.5163 78.6062L122.427 76.7121C126.223 76.7121 130.019 75.765 133.816 73.8709C145.204 67.2415 149 53.0356 143.306 41.6709Z" fill="#0053B4"/>
    <path className="flower-loader-petal flower-loader-petal-first" d="M74.9748 2.84131C62.6372 2.84131 52.1978 13.259 52.1978 25.5707C52.1978 29.359 53.1468 33.1472 55.0449 36.9354L73.0767 71.9766C73.0767 72.9236 74.0258 73.8707 74.9748 73.8707C75.9239 73.8707 76.8729 72.9236 76.8729 71.9766L94.9048 37.8825C96.8029 34.0942 97.7519 30.306 97.7519 26.5178C97.7519 13.259 87.3124 2.84131 74.9748 2.84131Z" fill="#0053B4"/>
  </SvgIcon>
}

export default FlowerLoader
