import Service from './Service'

import { submissionProcessorApi } from '~/config/aws'

class SubmissionProcessorService extends Service {
  constructor () {
    super(submissionProcessorApi)
  }
}

export default SubmissionProcessorService
