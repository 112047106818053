import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import { Box, styled, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enGB } from 'date-fns/locale'
import { HelmetProvider } from 'react-helmet-async'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { releaseVersion, stage } from './config/app'
import { amplify } from './config/aws'
import { bugSnagKey } from './config/bugsnag'
import { theme } from './config/theme'

import NotificationContainer from './components/Notifications/NotificationContainer'
import { router } from './routes/router'
import store from './redux/store'

import './App.scss'

Bugsnag.start({
  apiKey: bugSnagKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: stage,
  enabledReleaseStages: ['development', 'staging', 'production'],
  appVersion: releaseVersion,
})

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

Amplify.configure(amplify)

const AppBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - calc(100vh - 100%));
  min-height: -webkit-fill-available;
  flex-grow: 1;
`

const App: React.FC = () => {
  const AppElements = (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider adapterLocale={enGB} dateAdapter={AdapterDateFns}>
            <AppBox className="App" sx={{ overflowX: 'hidden' }}>
              <NotificationContainer />
              <RouterProvider router={router} />
            </AppBox>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  )

  if (ErrorBoundary) {
    return (
      <ErrorBoundary>
        {AppElements}
      </ErrorBoundary>
    )
  }

  return AppElements
}

export default App
