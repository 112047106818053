import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { removeNotification } from '~/redux/features/notifications/notificationSlice'
import { RootState, useAppDispatch } from '~/redux/store'

import { Notification } from '~/types/notifications/Notification'

import NotificationItem from './NotificationItem'

const NotificationContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const notifications = useSelector((state: RootState) => state.notification.notifications)
  const [notification, setNotifcation] = useState<Notification | null>(null)

  useEffect(() => {
    if (notifications.length > 0) {
      if (notification === null || (notification !== null && notifications[0].id !== notification.id)) {
        setNotifcation(notifications[0])
        if (notification) dispatch(removeNotification(notification.id))
      }
    } else {
      setNotifcation(null)
    }
  }, [notifications])

  const handleClose = (id: string): void => {
    dispatch(removeNotification(id))
  }

  return <NotificationItem notification={notification} onClose={handleClose} />
}

export default NotificationContainer
