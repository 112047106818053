import { useEffect, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { getPromotionalUserMeta, signOut } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { RootState, useAppDispatch } from '~/redux/store'
import { ROUTE_LOGIN } from '~/routes/Routes'
import { isNotifiable } from '~/types/guards/errors'

import Account from './Account'

const AccountView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.auth.promotionalUser)
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchPromotionalUserMeta = async (): Promise<void> => {
    if (user?.userId) {
      await dispatch(getPromotionalUserMeta({ userId: user?.userId, signal: abort.current.signal })).unwrap()
    }
  }

  const initiateSignOut = async (): Promise<void> => {
    try {
      await dispatch(signOut()).unwrap()
      navigate(ROUTE_LOGIN)
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Unable to sign out' }))

      if (isNotifiable(error)) {
        Bugsnag.notify(error)
      }
    }
  }

  const handleSignOut = (): void => {
    void initiateSignOut()
  }

  return <>
    <Helmet>
      <title>Account | Ecover Loyalty Rewards</title>
    </Helmet>
    <Account user={user} onFetchPromotionalUserMeta={fetchPromotionalUserMeta} signOut={handleSignOut} />
  </>
}

export default AccountView
