import * as React from 'react'
import { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

interface PasswordFieldProps {
  id: string
  name: string
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  error?: boolean
  helperText?: string | boolean
  required: boolean
  autoComplete?: string
  fullWidth?: boolean
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  required,
  autoComplete,
  fullWidth = true,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      fullWidth={fullWidth}
      id={id}
      name={name}
      type={showPassword ? 'text' : 'password'}
      label={label}
      margin="normal"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      required={required}
      autoComplete={autoComplete}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((current) => !current)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
