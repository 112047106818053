import { lazy } from 'react'

type LazyPreloadFactory = () => Promise<{
  default: React.ComponentType<any>
}>

type LazyPreload = React.LazyExoticComponent<React.ComponentType<any>> & { preload: LazyPreloadFactory }

export function lazyWithPreload (factory: LazyPreloadFactory): LazyPreload {
  const component: any = lazy(factory)
  component.preload = factory

  return component as LazyPreload
}
